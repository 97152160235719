import React from "react";
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";

class MemberNumberDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        return (
            <Dialog
                open={this.props.open_dialog}>
                <DialogTitle>
                    <Typography variant="h6" align="center" color="primary" gutterBottom>
                        {this.props.access_code === null ? "Staff Number" : "Access Code" }
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText align="center">
                        <TextField
                            error={false}
                            label={this.props.access_code === null ? "Enter staff number" : "Enter access code" }
                            value={this.props.member_number}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={this.props.onChange}
                            required
                            fullWidth
                        />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.props.closeDialog} color="grey">
                        Cancel
                    </Button>
                    <Button onClick={this.props.submit} variant="contained" color="primary">
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default MemberNumberDialog;
