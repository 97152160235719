import React from "react";
import CssBaseline from '@material-ui/core/CssBaseline';
import {withStyles} from '@material-ui/core/styles';
import {createMuiTheme, ThemeProvider} from '@material-ui/core/styles';
import {red, grey} from '@material-ui/core/colors';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import MotorcycleIcon from '@material-ui/icons/Motorcycle';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import Container from "@material-ui/core/Container";
import runtimeEnv from '@mars/heroku-js-runtime-env';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import {encrypt, decrypt} from "../utils/crypto_encryption";
import {authorization_headers} from "../utils/api_authorization";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import RedeemIcon from '@material-ui/icons/Redeem';
import InputBase from "@material-ui/core/InputBase";
import Snackbar from "@material-ui/core/Snackbar";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import MuiAlert from "@material-ui/lab/Alert";

const env = runtimeEnv();

const appTheme = createMuiTheme({
    palette: {
        primary: {
            main: red[900],
        },
        white: {
            main: grey[50],
        }
    },
});
const styles = theme => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    fields_toolbar: {
        borderColor: '#737373',
        border: '2px solid',
        borderRadius: '5px',
        marginLeft: '20px',
        marginRight: '20px'
    },
    iconButton: {
        padding: 10,
    },
    cat_extended_btn: {
        width: '110px',
        height: '30px',
    },
    btn_clear_cart: {
        width: '40%',
        float: 'left',
    },
    btn_checkout: {
        marginBottom: '2%',
    },
    stickToBottom: {
        top: 'auto',
        bottom: 0,
    },
    table: {
        width: '100%',
    },
    table_cell: {
        border: 0,
    },
    list: {
        marginTop: '50px',
        marginBottom: '20px',
        borderRadius: '0px',
    },
    pre_refilled_info: {
        fontSize: '14px',
        color: grey[600]
    },
    snackbar: {
        marginTop: '40px'
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
});

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class ConfirmDelivery extends React.Component {
    constructor(props) {
        super(props);
        let full_address = null
        let phone_number = null
        let coordinates = []
        let address_notes = null
        let created_order = {}

        if (localStorage.getItem('shipping_address') !== null) {
            let shipping_address = JSON.parse(localStorage.getItem('shipping_address'))
            full_address = shipping_address.addres_name + ', ' + shipping_address.formatted_address
            coordinates = {lat: shipping_address.latitude, lng: shipping_address.longitude}
        }

        if (localStorage.getItem('phone_number') !== null) {
            phone_number = localStorage.getItem('phone_number');
        }

        if (localStorage.getItem('address_notes') !== null) {
            address_notes = localStorage.getItem('address_notes');
        }

        if (localStorage.getItem('created_order') !== null) {
            created_order = JSON.parse(localStorage.getItem('created_order'));
        }

        this.state = {
            phone_number: phone_number,
            full_address: full_address,
            address_notes: address_notes,
            coordinates: coordinates,
            created_order: created_order,
            opt_in_for_marketing: false,
            voucher_code: null,
            mode_of_payment: localStorage.getItem('mode_of_payment'),
            voucher_applied: false,
            loading: false,
            open_snackbar: false,
            snackbar_message: null,
            snackbar_severity: null,
        }

    }

    cartQuantity() {
        let sum = 0;
        this.state.created_order.data.order_data.line_items.forEach(function (item) {
            sum += item.quantity
        })

        return sum;
    }

    deliveryFee() {
        return this.state.created_order.delivery_fee_amount
    }

    productsAmount() {
        let total = 0;
        this.state.created_order.data.order_data.line_items.forEach(function (item) {
            total += (item.quantity * parseInt(item.price))
        })

        return total;
    }

    cartTotal() {
        let total = 0;
        this.state.created_order.data.order_data.line_items.forEach(function (item) {
            total += (item.quantity * parseInt(item.price))
        })

        total += parseInt(this.state.created_order.delivery_fee_amount)

        return total;
    }

    preVoucherValue() {
        let total = 0;
        let line_items = this.state.created_order.data.order_data.org_line_items ||
            this.state.created_order.data.order_data.line_items
        line_items.forEach(function (item) {
            total += (item.quantity * parseInt(item.price))
        })

        return total;
    }

    voucherValue() {
        return (this.preVoucherValue() - this.cartTotal() - parseInt(this.state.created_order.delivery_fee_amount))
    }

    toCurrency(number) {
        const formatter = new Intl.NumberFormat('en-us', {
                style: 'currency',
                currency: 'KSH',
            }
        )

        return formatter.format(number);
    }

    backToShippingDetailsPage() {
        const {history} = this.props;
        history.push('/shipping_details');
    }

    redirectToOrderingPage = () => {
        const {history} = this.props;

        if (this.cartQuantity() === 0) {
            history.push('/ordering');
        }
    }

    requestData() {
        const data = {
            order_id: this.state.created_order.id,
            opt_in_for_marketing: this.state.opt_in_for_marketing,
        }

        return JSON.stringify(data);
    }

    makePayment = () => {
        const server_api_url = env.REACT_APP_SERVER_API_URL + "/payments/create";

        fetch(server_api_url,
            {
                method: "POST",
                headers: authorization_headers(),
                body: encrypt(this.requestData()),
            })
            .then(res => {
                res.text().then(text => {
                    // let data = JSON.parse(decrypt(text))
                    const {history} = this.props;
                    history.push('/payment_status');
                }).catch(() => this.setState({hasErrors: true}))
            })
            .catch(() => this.setState({hasErrors: true}))
    };


    changeVoucherCode = (event) => {
        let voucher_code = event.target.value;
        this.setState({voucher_code: voucher_code})
    }

    submitVoucherCode = () => {
        let data = {
            order_id: this.state.created_order.id,
            voucher_code: this.state.voucher_code,
        }

        const server_api_url = env.REACT_APP_SERVER_API_URL + "/orders/apply_voucher_code";

        this.setState({loading: true})

        let error_message = "Your order does not meet the conditions for this voucher to be valid. Please check " +
            "voucher number and/or offer details."

        fetch(server_api_url,
            {
                method: "POST",
                headers: authorization_headers(),
                body: encrypt(JSON.stringify(data)),
            })
            .then(res => {
                res.text().then(text => {
                    if (res.ok) {
                        let data = JSON.parse(decrypt(text))

                        this.setState({
                            snackbar_message: "Voucher code successfully applied", snackbar_severity: "success",
                            open_snackbar: true, loading: false, created_order: data, voucher_applied: true,
                        })

                        localStorage.setItem('created_order', JSON.stringify(this.state.created_order))
                    } else {
                        this.setState({
                            snackbar_message: error_message, open_snackbar: true, loading: false,
                            voucher_applied: false,
                        })
                    }
                }).catch(() => {
                    this.setState({
                        snackbar_message: error_message, open_snackbar: true, loading: false, voucher_applied: false,
                    })
                })
            })
            .catch(() => {
                this.setState({
                    snackbar_message: error_message, open_snackbar: true, loading: false, voucher_applied: false,
                })
            })
    }

    closeSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({open_snackbar: false, snackbar_severity: null})
    };

    payrollOrMpesaPhoneNumber(classes) {
        if (this.state.mode_of_payment === 'payroll') {
            return (
                <ListItem>
                    <ListItemAvatar>
                        <ShoppingBasketIcon color="primary"/>
                    </ListItemAvatar>
                    <ListItemText
                        primary={"MODE OF PAYMENT FOR PRODUCTS (" + this.toCurrency(this.productsAmount()) + ")"}
                        secondary={
                            <Typography className={classes.pre_refilled_info}>
                                Payroll
                            </Typography>
                        }/>
                </ListItem>
            )
        }
    }

    displayPreVoucherValue(classes) {
        if (this.state.voucher_applied == false)
            return null

        return (
            <TableRow>
                <TableCell align="left" className={classes.table_cell}>Pre-voucher
                    value:</TableCell>
                <TableCell align="right" className={classes.table_cell}>
                    {this.toCurrency(this.preVoucherValue())}
                </TableCell>
            </TableRow>
        )
    }

    displayVoucherValue(classes) {
        if (this.state.voucher_applied == false)
            return null

        return (
            <TableRow>
                <TableCell align="left" className={classes.table_cell}>Voucher
                    value:</TableCell>
                <TableCell align="right" className={classes.table_cell}>
                    -{this.toCurrency(this.voucherValue())}
                </TableCell>
            </TableRow>
        )
    }

    confirmButtonLabel() {
        if (this.state.mode_of_payment === 'payroll' && this.state.created_order.delivery_fee_amount === 0) {
            return "CONFIRM"
        } else if (this.state.mode_of_payment === 'payroll' && this.state.created_order.delivery_fee_amount > 0) {
            return "MAKE PAYMENT"
        } else {
            return "MAKE PAYMENT"
        }
    }

    paymentMessage() {
        let message = ""
        if (this.state.mode_of_payment === 'payroll' && this.state.created_order.delivery_fee_amount === 0) {
            message = "PRODUCT AMOUNT (" + this.toCurrency(this.productsAmount()) + ") will be deducted from your salary."
        } else if (this.state.mode_of_payment === 'payroll' && this.state.created_order.delivery_fee_amount > 0) {
            message = "PRODUCT AMOUNT (" + this.toCurrency(this.productsAmount()) + ") will be deducted from your salary. " +
                "The DELIVERY FEE (" + this.toCurrency(this.state.created_order.delivery_fee_amount) +
                ") will be charged immediately via Mpesa. Please unlock your phone to receive an MPESA " +
                "payment prompt and enter your PIN to make payment."
        } else {
            message = "Please unlock your phone.You will receive an MPESA payment prompt of " +
                this.toCurrency(this.cartTotal()) + " at the phone number above. Enter your PIN to make payment."
        }

        return (
            <Typography align="center">
                {message}
            </Typography>
        )
    }

    optInForMarketing = (event) => {
        this.setState({opt_in_for_marketing: event.target.checked})
    }

    optedInForMarketing() {
        if (this.state.created_order.opted_in_for_marketing !== true) {
            return (
                <ListItem style={{border: "1px solid", borderColor: grey[400], padding: '15px'}}>
                    <FormControlLabel
                        control={<Checkbox
                            checked={this.state.opt_in_for_marketing}
                            onChange={this.optInForMarketing}
                            color="primary"
                            name="checkedA"/>}
                        label="Check this box if you'd like to be alerted on special offers, exciting promotions
                        and other marketing information."
                    />
                </ListItem>
            )
        }
    }

    render() {
        const {classes} = this.props;
        this.redirectToOrderingPage();

        return (
            <React.Fragment>
                <CssBaseline/>
                <ThemeProvider theme={appTheme}>
                    <AppBar position="fixed" color="white">
                        <Toolbar>
                            <IconButton className={classes.iconButton} aria-label="directions" color="primary"
                                        onClick={() => this.backToShippingDetailsPage()}>
                                <ArrowBackIosIcon/>
                            </IconButton>
                            <Typography className={classes.input} align="center">
                                <b>Confirm Delivery</b>
                            </Typography>
                            <IconButton className={classes.iconButton} aria-label="directions" color="primary"
                                        disableRipple>
                                <MotorcycleIcon/>
                            </IconButton>
                        </Toolbar>
                    </AppBar>

                    <List className={classes.list}>
                        <ListItem>
                            <ListItemAvatar>
                                <CheckCircleOutlineIcon color="primary"/>
                            </ListItemAvatar>
                            <ListItemText primary="DELIVERY ADDRESS"
                                          secondary={
                                              <Typography className={classes.pre_refilled_info}>
                                                  {this.state.full_address}<br/>
                                                  {this.state.address_notes}
                                              </Typography>
                                          }/>
                        </ListItem>
                        <ListItem>
                            <ListItemAvatar>
                                <CheckCircleOutlineIcon color="primary"/>
                            </ListItemAvatar>
                            <ListItemText primary="PHONE NUMBER" secondary={
                                <Typography className={classes.pre_refilled_info}>
                                    {this.state.phone_number}
                                </Typography>
                            }/>
                        </ListItem>
                        {this.payrollOrMpesaPhoneNumber(classes)}

                        <ListItem>
                            <ListItemAvatar>
                                <MotorcycleIcon color="primary"/>
                            </ListItemAvatar>
                            <ListItemText
                                primary={"MODE OF PAYMENT FOR DELIVERY FEE (" +
                                this.toCurrency(this.state.created_order.delivery_fee_amount) + ")"}
                                secondary={
                                    <Typography className={classes.pre_refilled_info}>
                                        Mpesa
                                    </Typography>
                                }/>
                        </ListItem>

                        {this.optedInForMarketing()}

                        <ListItem>
                            <ListItemText
                                primary={this.paymentMessage()}/>
                        </ListItem>
                    </List>

                    <Toolbar className={classes.fields_toolbar} style={{marginBottom: '220px'}}>
                        <IconButton className={classes.iconButton}>
                            <RedeemIcon/>
                        </IconButton>
                        <InputBase
                            className={classes.input}
                            placeholder="Enter voucher code"
                            value={this.state.voucher_code}
                            onChange={this.changeVoucherCode}
                        />
                        <Button aria-label="directions" color="primary" variant="contained"
                                onClick={this.submitVoucherCode}>
                            Apply
                        </Button>
                    </Toolbar>

                    <AppBar className={classes.stickToBottom} color="white">
                        <TableContainer component={Paper}>
                            <Table className={classes.table} size="small">
                                <TableBody>
                                    <TableRow>
                                        <TableCell align="left" className={classes.table_cell}>Number of
                                            products:</TableCell>
                                        <TableCell align="right" className={classes.table_cell}>
                                            {this.cartQuantity()}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell align="left" className={classes.table_cell}>Delivery fee:</TableCell>
                                        <TableCell align="right" className={classes.table_cell}>
                                            {this.toCurrency(this.state.created_order.delivery_fee_amount)}
                                        </TableCell>
                                    </TableRow>
                                    {this.displayPreVoucherValue(classes)}
                                    {this.displayVoucherValue(classes)}
                                    <TableRow>
                                        <TableCell align="left" className={classes.table_cell}>
                                            <b>TOTAL AMOUNT:</b>
                                        </TableCell>
                                        <TableCell align="right" className={classes.table_cell}>
                                            <b>{this.toCurrency(this.cartTotal())}</b>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>

                            <Container maxWidth="sm">
                                <Button variant="contained" color="primary" size="large" align="center"
                                        className={classes.btn_checkout} onClick={this.makePayment} fullWidth>
                                    <b>{this.confirmButtonLabel()}</b>
                                </Button>
                            </Container>
                        </TableContainer>
                    </AppBar>

                    <Snackbar
                        anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                        className={classes.snackbar}
                        open={this.state.open_snackbar}>
                        <Alert onClose={() => this.closeSnackbar()}
                               severity={this.state.snackbar_severity || "warning"}>
                            {this.state.snackbar_message}
                        </Alert>
                    </Snackbar>
                    <Backdrop className={classes.backdrop} open={this.state.loading}>
                        <CircularProgress color="inherit"/>
                    </Backdrop>
                </ThemeProvider>
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(ConfirmDelivery);
