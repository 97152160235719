import React from "react";
import CssBaseline from '@material-ui/core/CssBaseline';
import {withStyles} from '@material-ui/core/styles';
import {createMuiTheme, ThemeProvider} from '@material-ui/core/styles';
import {red, grey} from '@material-ui/core/colors';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Typography from '@material-ui/core/Typography';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import LocalBarIcon from '@material-ui/icons/LocalBar';
import Avatar from '@material-ui/core/Avatar';

const appTheme = createMuiTheme({
    palette: {
        primary: {
            main: red[900],
        },
        white: {
            main: grey[50],
        }
    },
});
const styles = theme => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
    cat_extended_btn: {
        width: '110px',
        height: '30px',
    },
    btn_clear_cart: {
        width: '40%',
        float: 'left',
    },
    btn_checkout: {
        width: '40%',
        float: 'right',
    },
    stickToBottom: {
        top: 'auto',
        bottom: 0,
    },
    table: {
        width: '100%',
    },
    table_cell: {
        border: 0,
    },
    list: {
        marginTop: '60px',
        marginBottom: '60px',
        borderRadius: '0px',
    },
    product_image: {
        backgroundColor: '#fafafa',
        color: '#bdbdbd'
    }
});

class ShoppingCart extends React.Component {
    constructor(props) {
        super(props);
        let shopping_cart = []

        if (localStorage.getItem('shopping_cart') !== null) {
            shopping_cart = JSON.parse(localStorage.getItem('shopping_cart'));
        }

        this.state = {
            shopping_cart: shopping_cart,
            search_query: "",
        }
    }

    removeFromCart(product_id) {
        let updated_cart = this.state.shopping_cart.slice();
        let selected_product = updated_cart.filter(function (item) {
            return item.id === product_id;
        })[0];

        if (selected_product !== undefined) {
            let index = updated_cart.findIndex(product => product.id === product_id);

            if ((selected_product.quantity === 0) || (selected_product.quantity === 1)) {
                updated_cart.splice(index, 1)
            } else {
                updated_cart[index].quantity--
            }

            localStorage.setItem('shopping_cart', JSON.stringify(updated_cart))
            this.setState({shopping_cart: updated_cart})
        }
    }

    addToCart(product_id) {
        let selected_product = this.state.shopping_cart.filter(function (item) {
            return item.id === product_id;
        })[0];

        if (selected_product !== undefined) {
            let existing_product = this.state.shopping_cart.filter(function (item) {
                return item.id === product_id;
            })[0];

            let updated_cart = this.state.shopping_cart.slice();

            if (existing_product !== undefined) {
                let index = updated_cart.findIndex(product => product.id === product_id);

                if (index !== -1) {
                    updated_cart[index].quantity++
                }
            } else {
                selected_product.quantity = 1
                updated_cart.push(selected_product)
            }

            localStorage.setItem('shopping_cart', JSON.stringify(updated_cart))
            this.setState({shopping_cart: updated_cart})
        }
    }

    cartQuantity() {
        let sum = 0;
        this.state.shopping_cart.forEach(function (item) {
            sum += item.quantity
        })

        return sum;
    }

    cartTotal() {
        let total = 0;
        this.state.shopping_cart.forEach(function (item) {
            total += (item.quantity * parseInt(item.price))
        })

        return total;
    }

    cartProductQualtity(product_id) {
        let selected_product = this.state.shopping_cart.filter(function (item) {
            return item.id === product_id;
        })[0];

        if (selected_product !== undefined) {
            return selected_product.quantity
        }
        return 0
    }

    toCurrency(number) {
        const formatter = new Intl.NumberFormat('en-us', {
                style: 'currency',
                currency: 'KSH',
            }
        )

        return formatter.format(number);
    }

    backToOrderingPage() {
        const {history} = this.props;
        history.push('/ordering');
    }

    clearCart() {
        let updated_cart = this.state.shopping_cart.slice();
        updated_cart.splice(0, updated_cart.length)
        localStorage.setItem('shopping_cart', JSON.stringify(updated_cart))
        this.setState({shopping_cart: updated_cart})
        this.backToOrderingPage()
    }

    checkout() {
        const {history} = this.props;
        history.push('/shipping_details');
    }

    productImage = (url, classes) => {
        if (url) {
            return (
                <Avatar src={url} variant="square" className={classes.product_image}/>
            )
        } else {
            return (
                <Avatar className={classes.product_image} variant="square">
                    <LocalBarIcon/>
                </Avatar>
            )
        }
    }

    render() {
        const {classes} = this.props;

        return (
            <React.Fragment>
                <CssBaseline/>
                <ThemeProvider theme={appTheme}>
                    <AppBar position="fixed" color="white">
                        <Toolbar>
                            <IconButton className={classes.iconButton} aria-label="directions" color="primary"
                                        onClick={() => this.backToOrderingPage()}>
                                <ArrowBackIosIcon/>
                            </IconButton>
                            <Typography className={classes.input} align="center">
                                <b>My Basket</b>
                            </Typography>
                            <IconButton className={classes.iconButton} aria-label="directions" color="primary"
                                        disableRipple>
                                <ShoppingBasketIcon/>
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                    <List className={classes.list}>
                        {this.state.shopping_cart.map((product, index) => (
                            <ListItem key={index}>
                                {this.productImage(product.image_url, classes)}
                                <ListItemText primary={product.name} secondary={this.toCurrency(product.price)}/>
                                <ButtonGroup color="primary" size="small" className={classes.group_buttons}>
                                    <Fab onClick={() => this.removeFromCart(product.id)}>
                                        <RemoveIcon/>
                                    </Fab>
                                    <Button variant="contained" color="primary">
                                        {this.cartProductQualtity(product.id)}
                                    </Button>
                                    <Fab onClick={() => this.addToCart(product.id)}>
                                        <AddIcon/>
                                    </Fab>
                                </ButtonGroup>
                            </ListItem>
                        ))}
                    </List>

                    <AppBar className={classes.stickToBottom} color="white">
                        <TableContainer component={Paper}>
                            <Table className={classes.table} size="small">
                                <TableBody>
                                    <TableRow>
                                        <TableCell align="left" className={classes.table_cell}>Number of
                                            products:</TableCell>
                                        <TableCell align="right" className={classes.table_cell}>
                                            {this.cartQuantity()}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell align="left" className={classes.table_cell}>
                                            <b>PRODUCT AMOUNT:</b>
                                        </TableCell>
                                        <TableCell align="right" className={classes.table_cell}>
                                            <b>{this.toCurrency(this.cartTotal())}</b>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>

                            <div className={classes.root}>
                                <Button variant="contained" className={classes.btn_clear_cart}
                                        onClick={() => this.clearCart()}>
                                    CLEAR BASKET
                                </Button>
                                <Button variant="contained" color="primary" className={classes.btn_checkout}
                                        onClick={() => this.checkout()}>
                                    CHECK OUT
                                </Button>
                            </div>
                        </TableContainer>
                    </AppBar>
                </ThemeProvider>
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(ShoppingCart);
