import React, {useEffect} from "react";
import {
    Route,
    HashRouter
} from "react-router-dom";
import {createBrowserHistory} from 'history';
import ReactGA from 'react-ga';
import Home from "./home/Home";
import OutletSelection from "./outlet/OutletSelection";
import Ordering from "./ordering/Ordering";
import ShoppingCart from "./shopping_cart/ShoppingCart";
import ShippingDetails from "./shipping_details/ShippingDetails";
import ConfirmDelivery from "./confirm_delivery/ConfirmDelivery";
import PaymentStatus from "./payment_status/PaymentStatus";
import DeliveryAddress from "./delivery_address/DeliveryAddress";
import HttpsRedirect from 'react-https-redirect';
import runtimeEnv from '@mars/heroku-js-runtime-env';
import TagManager from 'react-gtm-module';

const env = runtimeEnv();
ReactGA.initialize(env.REACT_APP_GOOGLE_ANALYTICS_CODE)

const tagManagerArgs = {gtmId: env.REACT_APP_GOOGLE_GTM_CODE, dataLayerName: 'PageDataLayer'}
TagManager.initialize(tagManagerArgs)

function Main(props) {
    window.location.href = "https://ke.thebar.com/";

    const history = createBrowserHistory();
    useEffect(() => {
        history.listen(location => {
            let endpoint = window.location.pathname + window.location.hash + window.location.search
            ReactGA.set({page: endpoint}) // Update the user's current page
            ReactGA.pageview(endpoint) // Record a pageview for the given page

            // Push data to TagManager
            TagManager.dataLayer({dataLayer: {page: endpoint}, dataLayerName: 'PageDataLayer'})
        })
    }, [history])

    return (
        <HashRouter history={history}>
            <HttpsRedirect>
                <div>
                    <div className="content">
                        <Route exact path="/" component={Home}/>
                        <Route exact path="/delivery_address" component={DeliveryAddress}/>
                        <Route exact path="/outlet_selection" component={OutletSelection}/>
                        <Route exact path="/ordering" component={Ordering}/>
                        <Route exact path="/shopping_cart" component={ShoppingCart}/>
                        <Route exact path="/shipping_details" component={ShippingDetails}/>
                        <Route exact path="/confirm_delivery" component={ConfirmDelivery}/>
                        <Route exact path="/payment_status" component={PaymentStatus}/>
                    </div>
                </div>
            </HttpsRedirect>
        </HashRouter>
    );
}

export default Main;
