import React from "react";
import CssBaseline from '@material-ui/core/CssBaseline';
import {withStyles} from '@material-ui/core/styles';
import {createMuiTheme, ThemeProvider} from '@material-ui/core/styles';
import {red, grey} from '@material-ui/core/colors';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Typography from '@material-ui/core/Typography';
import MapIcon from '@material-ui/icons/Map';
import Container from '@material-ui/core/Container';
import PhoneIcon from '@material-ui/icons/Phone';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import InputBase from "@material-ui/core/InputBase";
import LocationOnIcon from '@material-ui/icons/LocationOn';
import ScheduleIcon from '@material-ui/icons/Schedule';
import RoomIcon from "@material-ui/icons/Room";
import GoogleMapReact from "google-map-react";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Moment from 'moment';
import parseHtml from 'html-react-parser';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import TextField from "@material-ui/core/TextField";
import runtimeEnv from '@mars/heroku-js-runtime-env';
import {encrypt, decrypt} from "../utils/crypto_encryption";
import {authorization_headers} from "../utils/api_authorization";

const env = runtimeEnv();

const appTheme = createMuiTheme({
    palette: {
        primary: {
            main: red[900],
        },
        white: {
            main: grey[50],
        }
    },
});

const styles = theme => ({
    page_container: {
        paddingTop: '80px',
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    text_fields: {
        marginBottom: '30px',
    },
    iconButton: {
        padding: 10,
    },
    btn_checkout: {
        margin: '2%'
    },
    stickToBottom: {
        top: 'auto',
        bottom: 0,
    },
    icon: {
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(2),
    },
    map_dimensions: {
        width: '100%',
        height: '320px',
    },
    snackbar: {
        marginTop: '40px'
    },
    deliver_later_and_notes_btn: {
        width: '100%'
    },
    fields_toolbar: {
        borderColor: '#737373',
        border: '2px solid',
        borderRadius: '5px',
    },
    fields_toolbar_address: {
        borderColor: '#ecebeb',
        border: '2px solid',
        borderRadius: '5px',
    },
    btn_wrapper: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
});

const mode_of_payments = [
    {
        value: 'mpesa',
        label: 'Mpesa',
    },
    {
        value: 'payroll',
        label: 'Payroll',
    }
];

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class ShippingDetails extends React.Component {
    constructor(props) {
        super(props);
        let shopping_cart = []
        let full_address = null
        let coordinates = []
        let delivery_data = {}
        let member = {}

        if (localStorage.getItem('shopping_cart') !== null) {
            shopping_cart = JSON.parse(localStorage.getItem('shopping_cart'));
        }

        if (localStorage.getItem('shipping_address') !== null) {
            let shipping_address = JSON.parse(localStorage.getItem('shipping_address'));
            full_address = shipping_address.addres_name + ', ' + shipping_address.formatted_address
            coordinates = {lat: shipping_address.latitude, lng: shipping_address.longitude}
        }

        if (localStorage.getItem('delivery_data') !== null) {
            delivery_data = JSON.parse(localStorage.getItem('delivery_data'));
        }

        if (localStorage.getItem('member') !== null) {
            member = JSON.parse(localStorage.getItem('member'));
        }

        this.state = {
            shopping_cart: shopping_cart,
            phone_number: localStorage.getItem('phone_number'),
            address_notes: localStorage.getItem('address_notes'),
            deliver_later: null,
            full_address: full_address,
            coordinates: coordinates,
            open_snackbar: false,
            snackbar_message: null,
            display_schedule_later_field: false,
            order_submitting: false,
            delivery_data: delivery_data,
            open_delivery_time_dialog: false,
            update_delivery_time_dialog: true,
            mode_of_payment: "mpesa",
            member: member,
        }
    }

    closeSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({open_snackbar: false})
    };

    backToShoppingCartPage() {
        const {history} = this.props;
        history.push('/shopping_cart');
    }

    cartQuantity() {
        let sum = 0;
        this.state.shopping_cart.forEach(function (item) {
            sum += item.quantity
        })

        return sum;
    }

    redirectToOrderingPage = () => {
        const {history} = this.props;

        if (this.cartQuantity() === 0) {
            history.push('/ordering');
        }
    }

    orderData() {
        const data = {
            customer_phone_number: this.state.phone_number,
            shipping_address: this.state.full_address,
            shipping_coordinates: [this.state.coordinates.lat, this.state.coordinates.lng],
            line_items: this.state.shopping_cart,
            address_notes: this.state.address_notes,
            deliver_later: this.state.deliver_later,
            outlet_id: this.state.delivery_data.distributor_id,
            mode_of_payment: this.state.mode_of_payment,
            member: this.state.member,
        }

        return JSON.stringify(data);
    }

    sendOrderToServer = () => {
        this.updateData()

        let deliver_after = Moment(this.state.delivery_data.deliver_after)
        let deliver_before = Moment(this.state.delivery_data.deliver_before)
        let deliver_later = Moment(this.state.deliver_later)
        let schedule_time = Moment(Moment().format("DD/MM/YYYY") +
            deliver_later.format(" h:mma"), "DD/MM/YYYY h:mma")
        let time_now = Moment()

        if (!this.state.phone_number) {
            this.setState({snackbar_message: "Please provide phone number.", open_snackbar: true})
            return
        } else if (this.state.phone_number.match(/^0\d{9}$/) === null) {
            this.setState({
                snackbar_message: "Invalid phone number. Start with 07 or 01.", open_snackbar: true,
            })
            return
        } else if (this.state.coordinates.length < 1) {
            this.setState({snackbar_message: "Please provide a physical address.", open_snackbar: true})
            return
        } else if (!this.state.address_notes) {
            this.setState({snackbar_message: "Please provide address notes.", open_snackbar: true})
            return
        } else if (((time_now > deliver_before) || (time_now < deliver_after)) &&
            (this.state.deliver_later === '' || this.state.deliver_later === null || this.state.deliver_later === 'null' ||
                deliver_later.isValid() === false)) {
            let message = "The outlet will be opened from " + deliver_after.format("dddd DD/MM/YYYY h:mma") +
                " to " + deliver_before.format("dddd DD/MM/YYYY h:mma") +
                ". To shop here you must schedule delivery time during these operating hours or pick another day."
            this.setState({
                snackbar_message: parseHtml(message),
                open_snackbar: true
            })

            return
        } else if (this.state.deliver_later !== '' && this.state.deliver_later !== null) {
            if (this.state.delivery_data) {
                let weekday = deliver_later.format('dddd').toLowerCase()
                let operating_time = this.state.delivery_data.outlet_operating_times[weekday]

                if (operating_time.operating === false) {
                    let message = "The outlet does not operate on " + deliver_later.format('dddd') +
                        ". Please pick another day."
                    this.setState({
                        snackbar_message: message,
                        open_snackbar: true
                    })
                    return
                }

                if (operating_time.opens_at !== '24 hours') {
                    let opens_at = Moment(Moment().format("dddd DD/MM/YYYY") + operating_time.opens_at,
                        "DD/MM/YYYY h:mma")
                    let closes_at = Moment(Moment().format("dddd DD/MM/YYYY") + operating_time.closes_at,
                        "DD/MM/YYYY h:mma")

                    let message = "On " + deliver_later.format('dddd YYYY/MM/DD') +
                        " the outlet opens from " + opens_at.format("h:mma") + " to " +
                        closes_at.format("h:mma") + ". To shop here you must schedule delivery" +
                        " time during these operating hours or pick another day."
                    
                    if (schedule_time < opens_at) {
                        this.setState({
                            snackbar_message: parseHtml(message),
                            open_snackbar: true
                        })
                        return
                    } else if (schedule_time > closes_at) {
                        this.setState({
                            snackbar_message: message,
                            open_snackbar: true
                        })
                        return
                    }
                }
            }
        }

        const server_api_url = env.REACT_APP_SERVER_API_URL + "/orders";

        this.setState({order_submitting: true})
        fetch(server_api_url,
            {
                method: "POST",
                headers: authorization_headers(),
                body: encrypt(this.orderData()),
            })
            .then(res => {
                res.text().then(text => {
                    if (res.ok) {
                        let data = JSON.parse(decrypt(text))
                        localStorage.setItem('created_order', JSON.stringify(data))
                        localStorage.setItem('mode_of_payment', this.state.mode_of_payment)
                        const {history} = this.props;
                        this.setState({order_submitting: false})
                        history.push('/confirm_delivery');
                    } else {
                        let error_message = "Error submitting order. Please contact support."
                        this.setState({
                            hasErrors: true, order_submitting: false, open_snackbar: true,
                            snackbar_message: error_message
                        })
                    }
                }).catch(() => {
                    let error_message = "Error submitting order. Please contact support."
                    this.setState({
                        hasErrors: true, order_submitting: false, open_snackbar: true,
                        snackbar_message: error_message
                    })
                })
            })
            .catch(() => {
                let error_message = "Error submitting order. Please contact support."
                this.setState({
                    hasErrors: true, order_submitting: false, open_snackbar: true,
                    snackbar_message: error_message
                })
            })
    };

    updateData = () => {
        localStorage.setItem('phone_number', this.state.phone_number)
        localStorage.setItem('address_notes', this.state.address_notes)
        localStorage.setItem('deliver_later', this.state.deliver_later)
    };

    changePhoneNumber = (event) => {
        let phone_number = event.target.value;
        this.setState({phone_number: phone_number})
    }

    changeAddressNotes = (event) => {
        let address_notes = event.target.value;
        this.setState({address_notes: address_notes})
    }

    changeDeliverLater = (event) => {
        let deliver_later = event.target.value;
        this.setState({deliver_later: deliver_later})
    }
    toggleDeliverLaterField = () => {
        if (this.state.display_schedule_later_field) {
            this.setState({display_schedule_later_field: false})
        } else {
            this.setState({display_schedule_later_field: true})
        }
    }

    clearPhoneField = () => {
        this.setState({phone_number: ''})
    }

    clearAddressNotesField = () => {
        this.setState({address_notes: ''})
    }

    clearDeliverLaterField = () => {
        this.setState({deliver_later: '', display_schedule_later_field: false})
    }

    closeDeliveryTimeDialog = () => {
        this.setState({open_delivery_time_dialog: false})
    }

    deliveryTimeDialog = () => {
        let deliver_from = Moment(this.state.delivery_data.deliver_after)
        let deliver_to = Moment(this.state.delivery_data.deliver_before)

        return (
            <Dialog
                open={this.state.open_delivery_time_dialog}
                onClose={() => this.closeDeliveryTimeDialog()}>
                <DialogTitle>Operating hours</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        The outlet will be opened
                        from {deliver_from.format("dddd DD/MM/YYYY h:mma")} to {deliver_to.format("dddd DD/MM/YYYY h:mma")}.
                        To shop here you must schedule delivery time during these operating hours or pick another day..
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => this.closeDeliveryTimeDialog()} color="primary" autoFocus>
                        Okay
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

    requestingOutOfOperatingHours = () => {
        let deliver_after = Moment(this.state.delivery_data.deliver_after)
        let deliver_before = Moment(this.state.delivery_data.deliver_before)
        let time_now = Moment()

        if (this.state.update_delivery_time_dialog === false)
            return

        if (time_now > deliver_before) {
            if (this.state.open_delivery_time_dialog === false)
                this.setState({open_delivery_time_dialog: true})
        } else if (time_now < deliver_after) {
            if (this.state.open_delivery_time_dialog === false)
                this.setState({open_delivery_time_dialog: true})
        }

        this.setState({update_delivery_time_dialog: false})
    }

    changeModeOfPayment = (event) => {
        let mode_of_payment = event.target.value;
        this.setState({mode_of_payment: mode_of_payment})
        localStorage.setItem('mode_of_payment', mode_of_payment)

        if (mode_of_payment === 'finance') {
            this.setState({open_dialog: true})
        } else {
            this.setState({open_dialog: false})
        }
    };

    modeOfPaymentField(classes) {
        if (this.state.delivery_data.only_members_can_order === 1) {
            return (
                <div>
                    <Toolbar className={classes.fields_toolbar}>
                        <IconButton className={classes.iconButton}>
                            <MonetizationOnIcon/>
                        </IconButton>
                        <TextField
                            className={classes.input}
                            label="Select mode of payment for products"
                            select
                            value={this.state.mode_of_payment}
                            onChange={this.changeModeOfPayment}
                            SelectProps={{
                                native: true,
                            }}>
                            {mode_of_payments.map((option) => (
                                <option key={option.value} value={option.value}>
                                    {option.label}
                                </option>
                            ))}
                        </TextField>
                    </Toolbar>
                    <br/>
                </div>
            )
        }
    }

    render() {
        const {classes} = this.props;
        this.redirectToOrderingPage();

        const LocationPin = () => (
            <RoomIcon color="primary" fontSize="large"/>
        )

        this.requestingOutOfOperatingHours();

        return (
            <React.Fragment>
                <CssBaseline/>
                <ThemeProvider theme={appTheme}>
                    <AppBar position="fixed" color="white">
                        <Toolbar>
                            <IconButton className={classes.iconButton} aria-label="directions" color="primary"
                                        onClick={() => this.backToShoppingCartPage()}>
                                <ArrowBackIosIcon/>
                            </IconButton>
                            <Typography className={classes.input} align="center">
                                <b>Delivery Details</b>
                            </Typography>
                            <IconButton className={classes.iconButton} aria-label="directions" color="primary"
                                        disableRipple>
                                <MapIcon/>
                            </IconButton>
                        </Toolbar>
                    </AppBar>

                    <Container className={classes.page_container} fullWidth>
                        {this.modeOfPaymentField(classes)}

                        <Toolbar className={classes.fields_toolbar}>
                            <IconButton className={classes.iconButton}>
                                <MonetizationOnIcon/>
                            </IconButton>
                            <TextField
                                className={classes.input}
                                label="Delivery Fee is paid immediately via Mpesa"
                                select
                                value="Mpesa"
                                disabled
                                SelectProps={{
                                    native: true,
                                }}>
                                {[{value: "Mpesa", label: "Mpesa"}].map((option) => (
                                    <option key={option.value} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </TextField>
                        </Toolbar>
                        <br/>

                        <Toolbar className={classes.fields_toolbar}>
                            <IconButton className={classes.iconButton}>
                                <PhoneIcon/>
                            </IconButton>
                            <InputBase
                                className={classes.input}
                                placeholder="Mpesa Phone Number"
                                value={this.state.phone_number}
                                onChange={this.changePhoneNumber}
                            />
                            <Button aria-label="directions"
                                    onClick={() => this.clearPhoneField()}>
                                Clear
                            </Button>
                        </Toolbar>
                        <br/>
                        <Toolbar className={classes.fields_toolbar}>
                            <IconButton className={classes.iconButton}>
                                <LocationOnIcon/>
                            </IconButton>
                            <InputBase
                                className={classes.input}
                                placeholder="Estate / Building / Apt. no"
                                value={this.state.address_notes}
                                onChange={this.changeAddressNotes}
                            />
                            <Button aria-label="directions"
                                    onClick={() => this.clearAddressNotesField()}>
                                Clear
                            </Button>
                        </Toolbar>
                        <br/>
                        <div className={classes.btn_wrapper}>
                            <Button align="center"
                                    onClick={() => this.toggleDeliverLaterField()}>
                                Schedule delivery time
                            </Button>
                        </div>
                        <Toolbar className={classes.fields_toolbar}
                                 style={{display: (this.state.display_schedule_later_field ? '' : 'none')}}>
                            <IconButton className={classes.iconButton}>
                                <ScheduleIcon/>
                            </IconButton>
                            <InputBase
                                className={classes.input}
                                placeholder="Schedule a delivery time"
                                type="datetime-local"
                                value={this.state.deliver_later}
                                onChange={this.changeDeliverLater}
                            />
                            <Button aria-label="directions"
                                    onClick={() => this.clearDeliverLaterField()}>
                                Clear
                            </Button>
                        </Toolbar>
                        <br/>

                        <Toolbar className={classes.fields_toolbar_address}>
                            <IconButton className={classes.iconButton}>
                                <MapIcon/>
                            </IconButton>
                            <InputBase
                                className={classes.input}
                                value={this.state.full_address}
                                disabled
                            />
                        </Toolbar>
                        <br/>
                        <div className={classes.map_dimensions}>
                            <GoogleMapReact
                                bootstrapURLKeys={{key: env.REACT_APP_MAPS_API_KEY}}
                                center={this.state.coordinates}
                                defaultZoom={15}
                                defaultOptions={{fullscreenControl: false, zoomControl: false}}>

                                <LocationPin
                                    lat={this.state.coordinates.lat}
                                    lng={this.state.coordinates.lng}
                                />
                            </GoogleMapReact>
                        </div>

                        <div>
                            <Snackbar
                                anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                                className={classes.snackbar}
                                open={this.state.open_snackbar}>
                                <Alert onClose={() => this.closeSnackbar()} severity="warning">
                                    {this.state.snackbar_message}
                                </Alert>
                            </Snackbar>
                        </div>
                        {this.deliveryTimeDialog()}
                    </Container>
                    <Backdrop className={classes.backdrop} open={this.state.order_submitting}>
                        <CircularProgress color="inherit"/>
                    </Backdrop>
                    <AppBar className={classes.stickToBottom} color="white">
                        <Button variant="contained" color="primary" size="large" className={classes.btn_checkout}
                                onClick={() => this.sendOrderToServer()}>
                            SUBMIT
                        </Button>
                    </AppBar>
                </ThemeProvider>
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(ShippingDetails);
