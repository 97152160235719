import React from "react";
import CssBaseline from '@material-ui/core/CssBaseline';
import {withStyles} from '@material-ui/core/styles';
import {createMuiTheme, ThemeProvider} from '@material-ui/core/styles';
import {red, grey} from '@material-ui/core/colors';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Typography from '@material-ui/core/Typography';
import MapIcon from '@material-ui/icons/Map';
import TextField from "@material-ui/core/TextField";
import Container from '@material-ui/core/Container';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputAdornment from "@material-ui/core/InputAdornment";
import PhoneIcon from '@material-ui/icons/Phone';
import LocationOnIcon from "@material-ui/icons/LocationOn";
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from "@material-ui/core/Backdrop";
import throttle from "lodash/throttle";
import Autocomplete from "@material-ui/lab/Autocomplete";
import parse from "autosuggest-highlight/parse";
import Grid from "@material-ui/core/Grid";
import runtimeEnv from '@mars/heroku-js-runtime-env';
import Moment from "moment";
import InputBase from "@material-ui/core/InputBase";
import {encrypt, decrypt} from "../utils/crypto_encryption";
import {authorization_headers} from "../utils/api_authorization";
import * as Cookies from "js-cookie";

const env = runtimeEnv();

const appTheme = createMuiTheme({
    palette: {
        primary: {
            main: red[900],
        },
        white: {
            main: grey[50],
        },
        grey: {
            main: grey[300],
        }
    },
});

const styles = theme => ({
    age_gate_toolbar: {
        border: 'none',
    },
    age_gate_input: {
        textAlign: 'center',
        border: '2px solid',
        borderColor: '#737373',
    },
    page_container: {
        paddingTop: '100px',
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    text_fields: {
        marginBottom: '30px',
    },
    iconButton: {
        padding: 10,
    },
    btn_checkout: {
        margin: '2%'
    },
    stickToBottom: {
        top: 'auto',
        bottom: 0,
    },
    icon: {
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(2),
    },
    places_list: {
        top: '150px',
        position: 'absolute',
        zIndex: '50',
        background: 'white',
        width: '85.5%',
        fontSize: '12px'
    },
    map_dimensions: {
        width: '100%',
        height: '470px',
    },
    snackbar: {
        marginTop: '40px'
    },
    fields_toolbar: {
        borderColor: '#737373',
        border: '2px solid',
        borderRadius: '5px',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    dialog_title: {
        width: '100%',
        backgroundColor: "black",
        color: "white",
        fontSize: '17px'
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '100%',
    },
    order_now_btn: {
        marginTop: '20px',
    },
    warning_text: {
        padding: '5%',
        fontSize: '12px',
    },
});

function loadScript(src, position, id) {
    if (!position) {
        return;
    }

    const script = document.createElement('script');
    script.setAttribute('async', '');
    script.setAttribute('id', id);
    script.src = src;
    position.appendChild(script);
}

const autocompleteService = {current: null};

function DeliveryAddress(props) {
    const {classes} = props
    const [inputValue, setInputValue] = React.useState('')
    const [options, setOptions] = React.useState([])
    const loaded = React.useRef(false)
    const googleMapRef = React.createRef()
    const googleMap = React.useRef(null)

    const [openAgeGateDialog, setAgeGateDialog] = React.useState(true)
    const [date_of_birth, setDateOfBirth] = React.useState({})

    const [loading, setLoading] = React.useState(false)

    const [open_snackbar, openSnackbar] = React.useState(false)
    const [snackbar_severity, setSnackbarSeverity] = React.useState('warning')
    const [snackbar_message, setSnackbarMessage] = React.useState(null)
    const [open_dialog, openDialog] = React.useState(false)

    const [out_of_coverage_client_id, setOutOfCoverageClientId] = React.useState(null)
    const [phone_number, setPhoneNumber] = React.useState(null)

    React.useEffect(() => {
        const skip_age_gate = Cookies.get("skip_age_gate")
        if (skip_age_gate === "true") {
            localStorage.setItem('age_above_18', 'true');
            setAgeGateDialog(false)
        }

        if (localStorage.getItem('shipping_address') !== null) {
            let shipping_address = JSON.parse(localStorage.getItem('shipping_address'))

            if (typeof window !== 'undefined' && !loaded.current) {
                new window.google.maps.Map(googleMapRef.current, {
                    zoom: 14,
                    center: {
                        lat: shipping_address.latitude,
                        lng: shipping_address.longitude
                    }
                });

                new window.google.maps.Marker({
                    position: {lat: shipping_address.latitude, lng: shipping_address.longitude},
                    map: googleMap.current
                })

                loaded.current = true
            }
        }
    });

    if (typeof window !== 'undefined' && !loaded.current) {
        if (!document.querySelector('#google-maps')) {
            loadScript(
                'https://maps.googleapis.com/maps/api/js?' +
                'key=' + env.REACT_APP_MAPS_API_KEY + '&libraries=places',
                document.querySelector('head'),
                'google-maps',
            );
        }

        loaded.current = true;
    }

    const handleChange = (event) => {
        setInputValue(event.target.value)
    }

    const fetchPlaceGeometry = (place_id) => {
        let map = new window.google.maps.Map(googleMapRef.current, {zoom: 15})

        let request = {
            placeId: place_id,
            fields: ['geometry', 'formatted_address', 'place_id', 'name', 'plus_code']
        };

        let infowindow = new window.google.maps.InfoWindow()
        let service = new window.google.maps.places.PlacesService(map)

        service.getDetails(request, function (place, status) {
            if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                let shipping_address = {
                    addres_name: place.name,
                    formatted_address: place.formatted_address,
                    latitude: place.geometry.location.lat(),
                    longitude: place.geometry.location.lng(),
                }

                localStorage.setItem('shipping_address', JSON.stringify(shipping_address))

                let marker = new window.google.maps.Marker({
                    map: map,
                    position: place.geometry.location
                });
                map.setCenter(place.geometry.location)
                window.google.maps.event.addListener(marker, 'click', function () {
                    infowindow.setContent('<div><strong>' + place.name + '</strong><br>' +
                        'Plus code: ' + place.plus_code + '<br>' +
                        place.formatted_address + '</div>')
                    infowindow.open(map, this)
                });

            }
        });
    }

    const closeSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        openSnackbar(false)
        setSnackbarSeverity('warning')
    };

    const sendOrderToServer = () => {
        let address_name
        let formatted_address
        let latitude
        let longitude

        if (localStorage.getItem('shipping_address') !== null) {
            let shipping_address = JSON.parse(localStorage.getItem('shipping_address'))
            address_name = shipping_address.addres_name
            formatted_address = shipping_address.formatted_address
            latitude = shipping_address.latitude
            longitude = shipping_address.longitude
        }

        if (!formatted_address || !address_name) {
            setSnackbarMessage("Please provide a physical address.")
            openSnackbar(true)
            return
        }

        setLoading(true)

        const server_api_url = env.REACT_APP_SERVER_API_URL + "/orders/physical_address";
        let request_data = {
            full_address: address_name + ', ' + formatted_address,
            coordinates: {lat: latitude, lng: longitude},
        }

        fetch(server_api_url,
            {
                method: "POST",
                headers: authorization_headers(),
                body: encrypt(JSON.stringify(request_data)),
            })
            .then(res => {
                    res.text().then(text => {
                        let data = JSON.parse(decrypt(text))
                        if (data.code === '00') {
                            localStorage.setItem('outlets_data', JSON.stringify(data))
                            const {history} = props;
                            history.push('/outlet_selection')
                        } else if (data.code === '11') {
                            openDialog(true)
                            setOutOfCoverageClientId(data.data.out_of_coverage_client_id)
                        } else if (data.code === '12') {
                            setSnackbarMessage("We could not get results from Google Maps. Please " +
                                "consider narrowing your location.")
                            openSnackbar(true)
                        } else {
                            setSnackbarMessage("Error submitting information. Please try again or contact support.")
                            openSnackbar(true)
                        }
                        setLoading(false)
                    }).catch((error) => {
                        console.log("Error: " + error)
                        setLoading(false)
                    })
                }
            )
            .catch((error) => {
                console.log("Error: " + error)
                setLoading(false)
            });
    }

    const fetch_api = React.useMemo(
        () =>
            throttle((request, callback) => {
                autocompleteService.current.getPlacePredictions(request, callback)
            }, 200),
        [],
    );

    React.useEffect(() => {
        let active = true;

        if (!autocompleteService.current && window.google) {
            autocompleteService.current = new window.google.maps.places.AutocompleteService()
        }
        if (!autocompleteService.current) {
            return undefined
        }

        if (inputValue === '') {
            setOptions([])
            return undefined
        }

        fetch_api({
            input: inputValue, location: new window.google.maps.LatLng(-0.023600, 37.906200),
            radius: 525000, componentRestrictions: {country: 'ke'}
        }, (results) => {
            if (active) {
                setOptions(results || [])
            }
        })

        return () => {
            active = false
        }
    }, [inputValue, fetch_api])

    const changePhoneNumber = (event) => {
        setPhoneNumber(event.target.value)
    }

    const closeAgeGateDialog = () => {
        setAgeGateDialog(true)
    };

    const updateDateOfBirth = React.useCallback((e) => {
        const {name, value} = e.target
        if (name) {
            let date_of_birth_data = date_of_birth;
            eval("date_of_birth_data." + name + " = " + parseInt(value))
            setDateOfBirth(date_of_birth)
        }
    }, [])

    const submitAgeGateDialog = event => {
        let date_of_birth_str = date_of_birth.date_of_birth + "/" + date_of_birth.month_of_birth + "/" +
            date_of_birth.year_of_birth
        let dateFormat = ['DD/MM/YYYY', 'DD/M/YYYY', 'D/MM/YYYY', 'D/M/YYYY']
        let date_of_birth_is_valid = Moment(date_of_birth_str, dateFormat, true).isValid();
        if (date_of_birth_is_valid === false) {
            setSnackbarMessage("Invalid date. Format should be DD/MM/YYYY")
            openSnackbar(true)
        } else {
            let years = Moment().diff(Moment(date_of_birth_str, dateFormat, true), 'years', false);
            if (years >= 18) {
                localStorage.setItem('age_above_18', 'true');
                setAgeGateDialog(false)
                openSnackbar(false)
                Cookies.set('skip_age_gate', true, {secure: true})
            } else {
                setSnackbarMessage("Age is below 18 years.")
                openSnackbar(true)
            }
        }
        event.preventDefault();
    };

    const closeDialog = () => {
        openDialog(false)
    }

    const notifyMe = () => {
        if (!phone_number) {
            setSnackbarMessage("Please provide phone number.")
            openSnackbar(true)
            return
        } else if (phone_number.match(/^0\d{9}$/) === null) {
            setSnackbarMessage("Invalid phone number. Start with 07 or 01.")
            openSnackbar(true)
            return
        }

        let address_name
        let formatted_address
        let latitude
        let longitude

        if (localStorage.getItem('shipping_address') !== null) {
            let shipping_address = JSON.parse(localStorage.getItem('shipping_address'))
            address_name = shipping_address.addres_name
            formatted_address = shipping_address.formatted_address
            latitude = shipping_address.latitude
            longitude = shipping_address.longitude
        }


        let request_data = {
            full_address: address_name + ', ' + formatted_address,
            coordinates: {lat: latitude, lng: longitude},
            phone_number: phone_number,
            out_of_coverage_client_id: out_of_coverage_client_id,
        }

        const server_api_url = env.REACT_APP_SERVER_API_URL + "/out_of_coverage_clients";

        setLoading(true)
        fetch(server_api_url,
            {
                method: "POST",
                headers: authorization_headers(),
                body: encrypt(JSON.stringify(request_data)),
            })
            .then(res => {
                res.text().then(text => {
                    // let data = JSON.parse(decrypt(text))
                    openSnackbar(true)
                    setSnackbarSeverity("success")
                    setSnackbarMessage("Information submitted.")
                    setLoading(false)
                    openDialog(false)
                }).catch(() => {
                    setLoading(false)
                })
            }).catch(() => {
            setLoading(false)
        })
    }

    const backToHomePage = () => {
        const {history} = props;
        history.push('/')
    }

    return (
        <React.Fragment>
            <CssBaseline/>
            <ThemeProvider theme={appTheme}>
                <AppBar position="fixed" color="white">
                    <Toolbar>
                        <IconButton className={classes.iconButton} aria-label="directions" color="primary"
                                    onClick={backToHomePage}>
                            <ArrowBackIosIcon/>
                        </IconButton>
                        <Typography className={classes.input} align="center">
                            <b>Delivery Address</b>
                        </Typography>
                        <IconButton className={classes.iconButton} aria-label="directions" color="primary"
                                    disableRipple>
                            <MapIcon/>
                        </IconButton>
                    </Toolbar>
                </AppBar>

                <Container className={classes.page_container}>
                    <div>
                        <Autocomplete
                            id="google-map-demo"
                            getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
                            filterOptions={(x) => x}
                            options={options}
                            autoComplete
                            includeInputInList
                            loadingText={'Searching'}
                            noOptionsText={'No results'}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Enter the delivery address"
                                    variant="outlined"
                                    placeholder="Example; Nairobi, Mvuli Apartments"
                                    className={props.classes.text_fields}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onChange={handleChange}
                                    fullWidth
                                />
                            )}
                            renderOption={(option) => {
                                const matches = option.structured_formatting.main_text_matched_substrings
                                const parts = parse(
                                    option.structured_formatting.main_text,
                                    matches.map((match) => [match.offset, match.offset + match.length]),
                                );

                                return (
                                    <Grid container alignItems="center"
                                          onClick={() => fetchPlaceGeometry(option.place_id)}>
                                        <Grid item>
                                            <LocationOnIcon className={props.classes.icon}/>
                                        </Grid>
                                        <Grid item xs>
                                            {parts.map((part, index) => (
                                                <span key={index} style={{fontWeight: part.highlight ? 700 : 400}}>
                                    {part.text}
                                </span>
                                            ))}

                                            <Typography variant="body2" color="textSecondary">
                                                {option.structured_formatting.secondary_text}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                );
                            }}
                        />
                        <div id="google-map" ref={googleMapRef} className={props.classes.map_dimensions}/>
                    </div>
                    <Dialog
                        open={open_dialog}>
                        <DialogTitle>
                            <Typography variant="h6" align="center" color="primary" gutterBottom>
                                Ooops!
                            </Typography>
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText align="center">
                                You are outside the coverage area for now.
                                <br/>The good news is we are continuously expanding our coverage every month so
                                check back again in a few weeks.
                                <br/>Would you like us to notify you via SMS when we expand into your area?

                                <br/><br/>
                                <TextField
                                    error={false}
                                    label="Enter your phone number"
                                    variant="outlined"
                                    placeholder="Example; 0728000000"
                                    className={classes.text_fields}
                                    value={phone_number}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <PhoneIcon/>
                                            </InputAdornment>
                                        ),
                                    }}
                                    onChange={changePhoneNumber}
                                    required
                                    fullWidth
                                />
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => closeDialog()} color="grey" autoFocus>
                                Cancel
                            </Button>
                            <Button onClick={() => notifyMe()} variant="contained" color="primary" autoFocus>
                                Notify me
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <div>
                        <Snackbar
                            anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                            className={classes.snackbar}
                            open={open_snackbar}>
                            <Alert onClose={() => closeSnackbar()} severity={snackbar_severity}>
                                {snackbar_message}
                            </Alert>
                        </Snackbar>
                    </div>
                </Container>

                <Dialog open={openAgeGateDialog} onClose={closeAgeGateDialog} aria-labelledby="form-dialog-title">
                    <form onSubmit={submitAgeGateDialog}>
                        <DialogTitle id="form-dialog-title" className={classes.dialog_title} align="center">
                            Can we see some ID?
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText align='center'>
                                Enter date of birth
                            </DialogContentText>
                            <AppBar position="sticky" color="white" elevation={0}>
                                <Toolbar className={classes.age_gate_toolbar}>
                                    <InputBase
                                        inputProps={{
                                            className: classes.age_gate_input, type: 'number',
                                            name: 'date_of_birth', maxlength: '2',
                                        }}
                                        placeholder="DD"
                                        onChange={updateDateOfBirth}
                                    />
                                    <InputBase
                                        inputProps={{
                                            className: classes.age_gate_input, type: 'number',
                                            name: 'month_of_birth', maxlength: '2',
                                        }}
                                        placeholder="MM"
                                        onChange={updateDateOfBirth}
                                    />
                                    <InputBase
                                        inputProps={{
                                            className: classes.age_gate_input, type: 'number',
                                            name: 'year_of_birth', maxlength: '4',
                                        }}
                                        onChange={updateDateOfBirth}
                                        placeholder="YYYY"
                                    />
                                </Toolbar>
                            </AppBar>
                            <DialogContentText align='center'>
                                <b>You must present a valid National ID or Passport on delivery. Drink Responsibly.</b>
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button variant="contained" color="primary" size="large" className={classes.order_now_btn}
                                    fullWidth={true} type="submit" autoFocus>
                                <b>PROCEED</b>
                            </Button>
                        </DialogActions>
                    </form>

                    <Typography variant="body1" align="center" className={classes.warning_text}>
                        <b>
                            EXCESSIVE ALCOHOL CONSUMPTION IS HARMFUL TO YOUR HEALTH. NOT FOR SALE TO PERSONS UNDER THE
                            AGE OF 18 YEARS.
                        </b>
                    </Typography>
                </Dialog>

                <Backdrop className={classes.backdrop} open={loading}>
                    <CircularProgress color="inherit"/>
                </Backdrop>
                <AppBar className={classes.stickToBottom} color="white">
                    <Button variant="contained" color="primary" size="large" className={classes.btn_checkout}
                            onClick={() => sendOrderToServer()}>
                        PROCEED
                    </Button>
                </AppBar>
            </ThemeProvider>
        </React.Fragment>
    );
}

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />
}

export default withStyles(styles)(DeliveryAddress)
