import React from "react";
import CssBaseline from '@material-ui/core/CssBaseline';
import {withStyles} from '@material-ui/core/styles';
import {createMuiTheme, ThemeProvider} from '@material-ui/core/styles';
import {red, grey, green, orange} from '@material-ui/core/colors';
import Button from '@material-ui/core/Button';
import AppBar from "@material-ui/core/AppBar";
import Typography from '@material-ui/core/Typography';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Container from "@material-ui/core/Container";
import Fab from '@material-ui/core/Fab';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import Grid from '@material-ui/core/Grid';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import HourglassFullIcon from '@material-ui/icons/HourglassFull';
import runtimeEnv from '@mars/heroku-js-runtime-env';
import ReactGA from "react-ga";
import {encrypt, decrypt} from "../utils/crypto_encryption";
import {authorization_headers} from "../utils/api_authorization";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

const env = runtimeEnv();

ReactGA.initialize(env.REACT_APP_GOOGLE_ANALYTICS_CODE)
ReactGA.plugin.require("ecommerce")

const appTheme = createMuiTheme({
    palette: {
        primary: {
            main: red[900],
        },
        white: {
            main: grey[50],
        },
        success: {
            main: red[500],
        },
    },
});
const styles = theme => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    btn_continue_shopping: {
        marginBottom: '2%',
    },
    stickToBottom: {
        top: 'auto',
        bottom: 0,
    },
    list: {
        marginTop: '10px',
        marginBottom: '60px',
        borderRadius: '0px',
    },
    success_icon: {
        backgroundColor: green[500],
        color: grey[50],
        marginTop: '40px'
    },
    fail_icon: {
        backgroundColor: red[500],
        color: grey[50],
        marginTop: '40px'
    },
    error_icon: {
        backgroundColor: red[500],
        color: grey[50],
        marginTop: '40px'
    },
    pending_icon: {
        backgroundColor: orange[200],
        color: grey[50],
        marginTop: '40px'
    },
    btn_success: {
        backgroundColor: green[500],
        color: grey[50],
    },
    btn_fail: {
        backgroundColor: red[500],
        color: grey[50],
    },
    btn_error: {
        backgroundColor: red[500],
        color: grey[50],
    },
    btn_pending: {
        backgroundColor: orange[500],
        color: grey[50],
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    pre_refilled_info: {
        fontSize: '14px',
        color: grey[600]
    },
});

class PaymentStatus extends React.Component {
    constructor(props) {
        super(props);
        let full_address = null
        let phone_number = null
        let created_order = {}
        let address_notes = null


        if (localStorage.getItem('shipping_address') !== null) {
            let shipping_address = JSON.parse(localStorage.getItem('shipping_address'));
            full_address = shipping_address.addres_name + ', ' + shipping_address.formatted_address
        }

        if (localStorage.getItem('address_notes') !== null) {
            address_notes = localStorage.getItem('address_notes');
        }

        if (localStorage.getItem('phone_number') !== null) {
            phone_number = localStorage.getItem('phone_number');
        }

        if (localStorage.getItem('created_order') !== null) {
            created_order = JSON.parse(localStorage.getItem('created_order'));
        }

        this.state = {
            phone_number: phone_number,
            full_address: full_address,
            address_notes: address_notes,
            created_order: created_order,
            opt_in_for_marketing: false,
            mode_of_payment: localStorage.getItem('mode_of_payment'),
            loading: true,
            delay: 10000,
            polling_count: 0,
            status: 'pending',
            is_open: true,
        }
    }

    componentDidMount() {
        this.timer = setInterval(() => this.fetchStatus(), 5000);
    }

    stopInterval() {
        clearInterval(this.timer);
        this.timer = null;
    }

    outletTillNumber() {
        return this.state.created_order.data.distributor.mpesa_account_number
    }

    outletNumberType() {
        return this.state.created_order.data.distributor.mpesa_account_type
    }

    outletName() {
        return this.state.created_order.data.distributor.name
    }

    outletPaybillAccountNumber() {
        return this.state.created_order.data.distributor.paybill_account_number
    }

    cartQuantity() {
        let sum = 0;
        this.state.created_order.data.order_data.line_items.forEach(function (item) {
            sum += item.quantity
        })

        return sum;
    }

    cartTotal() {
        let total = 0;
        this.state.created_order.data.order_data.line_items.forEach(function (item) {
            total += (item.quantity * parseInt(item.price))
        })

        total += parseInt(this.state.created_order.delivery_fee_amount)

        return total;
    }

    paymentAmount() {
        if (this.state.mode_of_payment === 'payroll' &&
            (this.state.created_order.delivery_fee_amount) > 0) {
            return (this.state.created_order.delivery_fee_amount)
        } else {
            return this.cartTotal()
        }
    }

    toCurrency(number) {
        const formatter = new Intl.NumberFormat('en-us', {
                style: 'currency',
                currency: 'KSH',
                minimumFractionDigits: 0,
            }
        )

        return formatter.format(number);
    }

    addGaEcommerceData = () => {
        let outlet = this.state.created_order.data.distributor
        let line_items = this.state.created_order.data.order_data.line_items

        ReactGA.plugin.execute('ecommerce', 'addTransaction', {
            id: this.state.created_order.id,
            affiliation: outlet.name,
            revenue: this.cartTotal(),
            shipping: this.state.created_order.delivery_fee_amount,
        })

        line_items.map((line_item) =>
            ReactGA.plugin.execute("ecommerce", "addItem", {
                id: this.state.created_order.id,
                sku: line_item.product_code,
                name: line_item.name,
                price: line_item.price,
                category: line_item.category,
                quantity: line_item.quantity,
            })
        )

        ReactGA.plugin.execute("ecommerce", "send")
        ReactGA.plugin.execute("ecommerce", "clear")
    }

    fetchStatus = () => {
        const server_api_payments_url = env.REACT_APP_SERVER_API_URL + "/payments/show?id=" + this.state.created_order.id;
        this.setState({polling_count: this.state.polling_count + 1})

        fetch(server_api_payments_url,
            {
                headers: authorization_headers(),
            })
            .then(res => {
                res.text().then(text => {
                    let data = JSON.parse(decrypt(text))

                    // Update opted_in_for_marketing status
                    this.setState({opted_in_for_marketing: data.opted_in_for_marketing})

                    if (data.status === 'failed' || data.status === 'success' || this.state.polling_count > 25) {
                        this.stopInterval();
                        this.setState({
                            loading: false,
                            status: data.status,
                            is_open: false,
                        });

                        if (data.status === 'success') {
                            this.addGaEcommerceData()
                        }
                    }
                }).catch(() => this.setState({hasErrors: true}))
            })
            .catch(() => this.setState({hasErrors: true}))
    }

    redirectToOrderingPage = () => {
        const {history} = this.props;

        if (this.cartQuantity() === 0) {
            history.push('/ordering');
        }
    }

    keepShopping() {
        localStorage.removeItem("shopping_cart");
        const {history} = this.props;
        history.push('/');
    };

    orderID() {
        const data = {
            order_id: this.state.created_order.id,
        }

        return JSON.stringify(data);
    }

    retryPayment = () => {
        const server_api_url = env.REACT_APP_SERVER_API_URL + "/payments/retry_payment";

        fetch(server_api_url,
            {
                method: "POST",
                headers: authorization_headers(),
                body: encrypt(this.orderID()),
            })
            .then(res => {
                res.text().then(text => {
                    // let data = JSON.parse(decrypt(text))
                    window.location.reload(false);
                }).catch(() => this.setState({hasErrors: true}));
            }).catch(() => this.setState({hasErrors: true}));

    };

    optInForMarketing = (event) => {
        this.setState({opt_in_for_marketing: event.target.checked})

        const server_api_url = env.REACT_APP_SERVER_API_URL + "/orders/opt_in_for_marketing";
        fetch(server_api_url,
            {
                method: "POST",
                headers: authorization_headers(),
                body: encrypt(JSON.stringify({
                    order_id: this.state.created_order.id,
                    opt_in_for_marketing: event.target.checked,
                })),
            })
            .then(res => {
                res.text().then(text => {
                }).catch(() => this.setState({hasErrors: true}))
            })
            .catch(() => this.setState({hasErrors: true}))
    }

    optedInForMarketing() {
        if (this.state.opted_in_for_marketing !== true) {
            return (
                <ListItem style={{border: "1px solid", borderColor: grey[400], padding: '15px'}}>
                    <FormControlLabel
                        control={<Checkbox
                            checked={this.state.opt_in_for_marketing}
                            onChange={this.optInForMarketing}
                            color="primary"
                            name="checkedA"/>}
                        label="Check this box if you'd like to be alerted on special offers, exciting promotions
                        and other marketing information."
                    />
                </ListItem>
            )
        }
    }

    render() {
        const {classes} = this.props;
        const status = this.state.status;
        let icon;
        let typography;
        let button;
        const account_number = () => {
            let account_type = this.outletNumberType();
            if (account_type === 'paybill') {
                return (
                    <span>Account Number: <b>{this.outletPaybillAccountNumber()}</b><br/></span>
                );
            }
        }
        this.redirectToOrderingPage();

        if (status === 'success') {
            icon = <Fab className={classes.success_icon} align="center" size="large" fullWidth>
                <CheckIcon/>
            </Fab>;
            typography =
                <b>Thank you for using Party Central. You will receive real time SMS updates when your order is
                    Received,
                    Picked-up and Delivered.</b>
            button = <Button variant="contained" color="primary" size="large" align="center"
                             className={classes.btn_continue_shopping} onClick={() => this.keepShopping()}
                             fullWidth>
                <b>KEEP SHOPPING</b>
            </Button>;
        } else if (status === 'failed') {
            icon = <Fab className={classes.fail_icon} align="center" size="large" fullWidth>
                <ClearIcon/>
            </Fab>;
            typography = <span>
                Sorry, we are unable to trigger instant Mpesa payment on your phone number. Either click ‘Retry’ or
                pay directly <b>in the next 5 minutes</b> to: <br/><br/>
                Mpesa <span
                style={{textTransform: 'capitalize'}}>{this.outletNumberType()}</span> Number: <b>{this.outletTillNumber()}</b> <br/>
                {account_number()}
                Amount: <b>{this.toCurrency(this.paymentAmount())}</b> <br/>
                (The business name is: <b>{this.outletName()}</b>). <br/><br/>
                If you cannot pay directly in the next 5 minutes, click 'Retry' when ready.
            </span>
            button = <Button variant="contained" color="primary" size="large" align="center"
                             className={classes.btn_continue_shopping} onClick={() => this.retryPayment()}
                             fullWidth>
                <b>RETRY PAYMENT</b>
            </Button>;
        } else {
            icon = <Fab className={classes.error_icon} align="center" size="large" fullWidth>
                <PriorityHighIcon/>
            </Fab>;
            typography = <span>
                Sorry, we are unable to trigger instant Mpesa payment on your phone number. Either click ‘Retry’ or
                pay directly <b>in the next 5 minutes</b> to: <br/><br/>
                Mpesa <span
                style={{textTransform: 'capitalize'}}>{this.outletNumberType()}</span> Number: <b>{this.outletTillNumber()}</b> <br/>
                {account_number()}
                Amount: <b>{this.toCurrency(this.paymentAmount())}</b> <br/>
                (The business name is: <b>{this.outletName()}</b>). <br/><br/>
                If you cannot pay directly in the next 5 minutes, click 'Retry' when ready.
            </span>
            button = <Button variant="contained" color="primary" size="large" align="center"
                             className={classes.btn_continue_shopping} onClick={() => this.retryPayment()}
                             fullWidth>
                <b>RETRY PAYMENT</b>
            </Button>;
        }

        if (this.state.loading) return (
            <React.Fragment>
                <CssBaseline/>
                <ThemeProvider theme={appTheme}>
                    <Container maxWidth="sm">
                        <Grid container>
                            <Grid xs={12}>
                                <Grid container justify="center">
                                    <Grid/>
                                    <Grid>
                                        <Fab className={classes.pending_icon} align="center" size="large" fullWidth>
                                            <HourglassFullIcon/>
                                        </Fab>
                                        <Backdrop className={classes.backdrop} open={this.state.is_open}>
                                            <CircularProgress color="inherit"/>
                                        </Backdrop>
                                    </Grid>
                                    <Grid/>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Typography align="center" fullWidth>
                            <b>Please wait while we process the payment.</b>
                        </Typography>
                    </Container>
                </ThemeProvider>
                /</React.Fragment>
        );

        return (
            <React.Fragment>
                <CssBaseline/>
                <ThemeProvider theme={appTheme}>
                    <Container maxWidth="sm">
                        <Grid container className={classes.root}>
                            <Grid xs={12}>
                                <Grid container justify="center">
                                    <Grid/>
                                    <Grid>
                                        {icon}
                                    </Grid>
                                    <Grid/>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Typography fullWidth>
                            {typography}
                        </Typography>
                    </Container>
                    <List className={classes.list}>
                        {this.optedInForMarketing()}
                        <ListItem>
                            <ListItemText primary="DELIVERY ADDRESS"
                                          secondary={
                                              <Typography className={classes.pre_refilled_info}>
                                                  {this.state.full_address}<br/>
                                                  {this.state.address_notes}
                                              </Typography>
                                          }/>
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="ORDER CODE" secondary={this.state.created_order.id}/>
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                primary="PURCHASED PRODUCTS"
                                secondary={
                                    this.state.created_order.data.order_data.line_items.map((product, index) => (
                                        <Typography>
                                            {product.quantity}x {product.name}
                                        </Typography>
                                    ))
                                }/>
                        </ListItem>
                    </List>

                    <AppBar className={classes.stickToBottom} color="white">
                        <TableContainer component={Paper}>
                            <Container maxWidth="sm">
                                {button}
                            </Container>
                        </TableContainer>
                    </AppBar>
                </ThemeProvider>
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(PaymentStatus);
