import React from "react";
import CssBaseline from '@material-ui/core/CssBaseline';
import {withStyles} from '@material-ui/core/styles';
import {createMuiTheme, ThemeProvider} from '@material-ui/core/styles';
import {red, grey, amber, green} from '@material-ui/core/colors';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import StoreIcon from '@material-ui/icons/Store';
import Badge from '@material-ui/core/Badge';
import Typography from '@material-ui/core/Typography';
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import MapIcon from "@material-ui/icons/Map";
import MemberNumberDialog from "./MemberNumberDialog"
import MemberOtpDialog from "./MemberOtpDialog"
import Snackbar from "@material-ui/core/Snackbar";
import runtimeEnv from "@mars/heroku-js-runtime-env";
import MuiAlert from "@material-ui/lab/Alert";
import {encrypt, decrypt} from "../utils/crypto_encryption";
import {authorization_headers} from "../utils/api_authorization";
import Moment from 'moment';

const env = runtimeEnv();

const appTheme = createMuiTheme({
    palette: {
        primary: {
            main: red[900],
        },
        white: {
            main: grey[50],
        }
    },
});

const styles = theme => ({
    customBadge: {
        backgroundColor: green[500],
        color: "white"
    },
    toolbar: {
        border: '2px solid',
        borderColor: amber[700],
        borderRadius: '5px',
    },
    group_btns: {
        padding: '10px',
    },
    group_btn: {
        borderRadius: '20px',
        margin: 5,
        fontSize: '12px',
        fontWeight: 'bold',
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
    cat_extended_btn: {
        width: '110px',
        height: '30px',
    },
    btn_bottom: {
        border: '0px',
        borderColor: red[900],
    },
    stickToBottom: {
        top: 'auto',
        bottom: 0,
    },
    out_of_stock_btn: {
        width: '70px',
        fontSize: '12px',
        borderRadius: '15px',
    },
    product_image: {
        backgroundColor: '#fafafa',
        color: '#bdbdbd'
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
});

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class OutletSelection extends React.Component {
    constructor(props) {
        super(props);
        let nearest_outlets = []

        if (localStorage.getItem('outlets_data') !== null) {
            nearest_outlets = JSON.parse(localStorage.getItem('outlets_data'));
        }

        this.state = {
            search_query: "",
            nearest_outlets: nearest_outlets.data,
            filtered_outlets: nearest_outlets.data,
            open_member_number_dialog: false,
            member_number: null,
            open_member_otp_dialog: false,
            member_otp: null,
            access_code: null,
            loading: false,
            open_snackbar: false,
            snackbar_message: null,
            snackbar_severity: null,
        }
    }

    selectOutlet(distributor_id) {
        let selected_outlet = this.state.nearest_outlets.filter(function (item) {
            return item.distributor_id === distributor_id;
        })[0];

        if (selected_outlet) {
            if (selected_outlet.open !== true) {
                selected_outlet.deliver_after = selected_outlet.next_opens_at
                selected_outlet.deliver_before = selected_outlet.next_closes_at
            }

            localStorage.setItem('delivery_data', JSON.stringify(selected_outlet))
            this.setState({access_code: null})

            if (selected_outlet.only_members_can_order === 1) {
                this.setState({open_member_number_dialog: true, member_number: null})
                return
            }

            if (selected_outlet.access_code !== null) {
                this.setState({
                    open_member_number_dialog: true, member_number: null,
                    access_code: selected_outlet.access_code
                })
                return
            }

            const {history} = this.props
            history.push('/ordering')
        }
    }

    closeMemberNumberDialog() {
        this.setState({open_member_number_dialog: false})
    }

    onChangeMemberNumber = (event) => {
        this.setState({member_number: event.target.value})
    }

    submitMemberNumberDialog() {
        let delivery_data = JSON.parse(localStorage.getItem('delivery_data'))
        let request_data = {
            outlet_id: delivery_data.distributor_id,
            member_number: this.state.member_number,
        }

        this.setState({loading: true, open_member_number_dialog: false})

        if (this.state.access_code !== null) {

            if (this.state.access_code === this.state.member_number) {
                this.setState({loading: false, open_member_number_dialog: false})
                const {history} = this.props
                history.push('/ordering')
            } else {
                this.setState({
                    snackbar_message: "Invalid access code",
                    open_snackbar: true, loading: false,
                })
            }
            return
        }

        fetch(env.REACT_APP_SERVER_API_URL + "/outlet_members/send_otp",
            {
                method: "POST",
                headers: authorization_headers(),
                body: encrypt(JSON.stringify(request_data)),
            })
            .then(res => {
                    res.text().then(text => {
                        let data = JSON.parse(decrypt(text))
                        if (res.status === 201) {
                            this.setState({open_member_otp_dialog: true, member_otp: null, loading: false})
                        } else {
                            this.setState({
                                snackbar_message: data.error,
                                open_snackbar: true, loading: false,
                            })
                        }
                    }).catch(() => {
                        this.setState({
                            snackbar_message: "Error submitting information. Please try again or contact support.",
                            open_snackbar: true, loading: false,
                        })
                    })
                }
            ).catch(() => {
            this.setState({
                snackbar_message: "Error submitting information. Please try again or contact support.",
                open_snackbar: true, loading: false,
            })
        })
    }

    closeMemberOtpDialog() {
        this.setState({open_member_otp_dialog: false})
    }

    onChangeMemberOtp = (event) => {
        this.setState({member_otp: event.target.value})
    }

    submitMemberOtpDialog() {
        let delivery_data = JSON.parse(localStorage.getItem('delivery_data'))
        let request_data = {
            outlet_id: delivery_data.distributor_id,
            member_number: this.state.member_number,
            member_otp: this.state.member_otp,
        }

        this.setState({loading: true, open_member_otp_dialog: false, member_otp: null})

        fetch(env.REACT_APP_SERVER_API_URL + "/outlet_members/validate_otp",
            {
                method: "POST",
                headers: authorization_headers(),
                body: encrypt(JSON.stringify(request_data)),
            })
            .then(res => {
                    res.text().then(text => {
                        let data = JSON.parse(decrypt(text))
                        if (res.status === 201) {
                            this.setState({loading: false})

                            localStorage.setItem('member', JSON.stringify(data))

                            const {history} = this.props
                            history.push('/ordering')
                        } else {
                            this.setState({
                                snackbar_message: data.error,
                                open_snackbar: true, loading: false,
                            })
                        }
                    }).catch(() => {
                        this.setState({
                            snackbar_message: "Error submitting information. Please try again or contact support.",
                            open_snackbar: true, loading: false,
                        })
                    })
                }
            ).catch(() => {
            this.setState({
                snackbar_message: "Error submitting information. Please try again or contact support.",
                open_snackbar: true, loading: false,
            })
        })
    }

    closeSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({open_snackbar: false, snackbar_severity: null})
    };

    openOrClosed(outlet, classes) {
        let delivery_fee = outlet.free_delivery == true ? "Free delivery" : "Delivery fees will apply"
        let outlet_pricing_type = outlet.rrp_price_enabled == true ? "RRP Price Guarantee" : "Discounts on Select Items"
        let dial_delivery_phone_number = outlet.dial_delivery_phone_number ? "Call To Order: " + outlet.dial_delivery_phone_number : ""

        if (outlet.open === true) {
            return (
                <span>
                    <Badge badgeContent={'open'} classes={{badge: classes.customBadge}}/><br/>
                    Closes at {Moment(outlet.deliver_before).format("LT")}<br/>
                    {outlet_pricing_type}<br/>
                    {delivery_fee}<br/>
                    {dial_delivery_phone_number}
                </span>
            )
        } else {
            return (
                <span>
                    <Badge badgeContent={'closed'} color="error"/><br/>
                    Opens on {outlet.opens_next_on}<br/>
                    {outlet_pricing_type}<br/>
                    {delivery_fee}<br/>
                    {dial_delivery_phone_number}
                </span>
            )
        }
    }

    outletDistance(outlet) {
        if (outlet.visible_nationwide === true) {
            return "DELIVERY WITHIN 72HRS ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎"
        } else {
            return outlet.distance.toFixed(1) + "km away‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎‎"
        }
    }

    redirectToHome = () => {
        const {history} = this.props
        let right_age = (localStorage.getItem('age_above_18') !== 'true')
        let outlets_data = (localStorage.getItem('outlets_data') === null)
        let shipping_address = (localStorage.getItem('shipping_address') === null)

        if (right_age || outlets_data || shipping_address) {
            history.push('/');
        }
    }

    backToDeliveryAddressPage() {
        const {history} = this.props;
        history.push('/delivery_address');
    }

    clearShoppingCart = () => {
        localStorage.removeItem('shopping_cart');
    }

    render() {
        const {classes} = this.props;
        this.redirectToHome()
        this.clearShoppingCart()

        return (
            <React.Fragment>
                <CssBaseline/>
                <ThemeProvider theme={appTheme}>
                    <AppBar position="sticky" color="white">
                        <Toolbar>
                            <IconButton className={classes.iconButton} aria-label="directions" color="primary"
                                        onClick={() => this.backToDeliveryAddressPage()}>
                                <ArrowBackIosIcon/>
                            </IconButton>
                            <Typography className={classes.input} align="center">
                                <b>Nearby Outlets</b>
                            </Typography>
                            <IconButton className={classes.iconButton} aria-label="directions" color="primary"
                                        disableRipple>
                                <MapIcon/>
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                    <List style={{marginBottom: '60px', borderRadius: '0px'}}>
                        {this.state.filtered_outlets.map((outlet, index) => (
                            <ListItem key={index}>
                                <Avatar variant="square" className={classes.product_image}>
                                    <StoreIcon/>
                                </Avatar>
                                <ListItemText
                                    primary={outlet.name}
                                    secondary={
                                        <div>
                                            <Typography variant="body2">
                                                {this.outletDistance(outlet)}
                                                {this.openOrClosed(outlet, classes)}
                                            </Typography>
                                        </div>
                                    }
                                    style={{paddingRight: '18px'}}>
                                </ListItemText>

                                <Button variant="contained" color="primary" size="small"
                                        className={classes.out_of_stock_btn}
                                        onClick={() => this.selectOutlet(outlet.distributor_id)}>
                                    Select
                                </Button>
                            </ListItem>
                        ))}
                    </List>

                    <MemberNumberDialog open_dialog={this.state.open_member_number_dialog}
                                        access_code={this.state.access_code}
                                        member_number={this.state.member_number}
                                        onChange={this.onChangeMemberNumber}
                                        closeDialog={() => this.closeMemberNumberDialog()}
                                        submit={() => this.submitMemberNumberDialog()}/>

                    <MemberOtpDialog open_dialog={this.state.open_member_otp_dialog}
                                     member_number={this.state.member_otp}
                                     onChange={this.onChangeMemberOtp}
                                     closeDialog={() => this.closeMemberOtpDialog()}
                                     submit={() => this.submitMemberOtpDialog()}/>

                    <Snackbar
                        anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                        className={classes.snackbar}
                        open={this.state.open_snackbar}>
                        <Alert onClose={() => this.closeSnackbar()}
                               severity={this.state.snackbar_severity || "warning"}>
                            {this.state.snackbar_message}
                        </Alert>
                    </Snackbar>
                    <Backdrop className={classes.backdrop} open={this.state.loading}>
                        <CircularProgress color="inherit"/>
                    </Backdrop>
                </ThemeProvider>
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(OutletSelection);
