import React from "react";
import CssBaseline from '@material-ui/core/CssBaseline';
import {withStyles} from '@material-ui/core/styles';
import {createMuiTheme, ThemeProvider} from '@material-ui/core/styles';
import {red, grey, amber, green} from '@material-ui/core/colors';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import Button from '@material-ui/core/Button';
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Container from '@material-ui/core/Container';
import LocalBarIcon from '@material-ui/icons/LocalBar';
import Avatar from '@material-ui/core/Avatar';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import runtimeEnv from '@mars/heroku-js-runtime-env';
import Moment from "moment";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";
import Badge from "@material-ui/core/Badge";
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import {encrypt, decrypt} from "../utils/crypto_encryption";
import {authorization_headers} from "../utils/api_authorization";
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import Grid from "@material-ui/core/Grid";
import ReactHtmlParser from "react-html-parser";

const env = runtimeEnv();

const appTheme = createMuiTheme({
    palette: {
        primary: {
            main: red[900],
        },
        white: {
            main: grey[50],
        }
    },
});

const styles = theme => ({
    customBadge: {
        backgroundColor: "#dc8a07",
        color: "white",
        width: "80px",
        fontWeight: "700"
    },
    outOfStockCustomBadge: {
        backgroundColor: "rgba(0, 0, 0, 0.54)",
        color: "white",
        width: "105px",
        fontWeight: "700"
    },
    toolbar: {
        border: '2px solid',
        borderColor: amber[700],
        borderRadius: '5px',
    },
    group_btns: {
        padding: '10px',
    },
    group_btn: {
        borderRadius: '20px',
        margin: 5,
        fontSize: '12px',
        fontWeight: 'bold',
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
    cat_extended_btn: {
        width: '110px',
        height: '30px',
    },
    btn_bottom: {
        border: '0px',
        borderColor: red[900],
    },
    stickToBottom: {
        top: 'auto',
        bottom: 0,
    },
    out_of_stock_btn: {
        height: '34px',
        width: '118px',
        minWidth: '118px',
        fontSize: '12px',
        borderRadius: '17px',
    },
    product_image: {
        backgroundColor: '#fafafa',
        color: '#bdbdbd'
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    list_primary_text: {
        width: '180px',
    },
    list_primary_text_for_out_of_stock: {
        width: '180px',
        color: 'rgba(0, 0, 0, 0.54)'
    },
    gridList: {
        width: '300px',
        height: '300px',
    },
});

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class Ordering extends React.Component {
    constructor(props) {
        super(props);
        let shopping_cart = []
        let delivery_data = {}

        if (localStorage.getItem('shopping_cart') !== null) {
            shopping_cart = JSON.parse(localStorage.getItem('shopping_cart'));
        }

        if (localStorage.getItem('delivery_data') !== null) {
            delivery_data = JSON.parse(localStorage.getItem('delivery_data'));
        }

        this.state = {
            shopping_cart: shopping_cart,
            products: [],
            product_categories: [],
            selected_category: null,
            selected_product: {},
            search_query: "",
            filtered_products: [],
            loading: false,
            delivery_data: delivery_data,
            open_delivery_time_dialog: false,
            update_delivery_time_dialog: true,
            open_snackbar: false,
            snackbar_message: null,
            snackbar_severity: null,
        }
    }

    closeSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({open_snackbar: false, snackbar_severity: null})
    };

    componentDidMount() {
        let delivery_data = JSON.parse(localStorage.getItem('delivery_data'))
        const server_products_api_url = env.REACT_APP_SERVER_API_URL + "/products?" +
            "distributor_id=" + delivery_data.distributor_id;

        this.setState({loading: true})

        fetch(server_products_api_url,
            {
                headers: authorization_headers(),
            })
            .then(res => {
                res.text().then(text => {
                    let data = JSON.parse(decrypt(text))
                    let dub_categories = data.map(item => item.category);
                    let categories = dub_categories.filter((e, i) => dub_categories.indexOf(e) === i).sort();
                    this.setState({
                        products: data,
                        filtered_products: data,
                        product_categories: ['Offers'].concat(categories),
                        loading: false,
                    })
                }).catch(() => {
                    this.setState({loading: false})
                    this.setState({hasErrors: true})
                })
            })
            .catch(() => {
                this.setState({loading: false})
                this.setState({hasErrors: true})
            })
    }

    updateDisplayedProducts() {
        let filtered_products = this.state.products;
        if (this.state.search_query !== null || this.state.search_query !== "") {
            filtered_products = this.state.products.filter((data) => {
                if (data.name.toLowerCase().includes(this.state.search_query.toLowerCase()) ||
                    data.category.toLowerCase().includes(this.state.search_query.toLowerCase())) {
                    return data;
                }
            })
        }

        this.setState({filtered_products: filtered_products})
    }

    updateSearchQuery(query) {
        this.setState({search_query: query, selected_category: null})
        this.updateDisplayedProducts();
    }

    removeFromCart(product_id) {
        let updated_cart = this.state.shopping_cart.slice();
        let selected_product = updated_cart.filter(function (item) {
            return item.id === product_id;
        })[0];

        if (selected_product !== undefined) {
            let index = updated_cart.findIndex(product => product.id === product_id);

            if ((selected_product.quantity === 0) || (selected_product.quantity === 1)) {
                updated_cart.splice(index, 1)
            } else {
                updated_cart[index].quantity--
            }

            localStorage.setItem('shopping_cart', JSON.stringify(updated_cart))
            this.setState({shopping_cart: updated_cart})
        }
    }

    sendOutOfStockAlert(product_id) {
        this.setState({loading: true})
        let delivery_data = JSON.parse(localStorage.getItem('delivery_data'))
        let shipping_address = JSON.parse(localStorage.getItem('shipping_address'))

        const server_api_url = env.REACT_APP_SERVER_API_URL + "/out_of_stock_alerts";

        let request_data = {
            product_id: product_id,
            distributor_id: delivery_data.distributor_id,
            location: shipping_address.formatted_address,
            coordinates: [shipping_address.latitude, shipping_address.longitude],
        }

        fetch(server_api_url,
            {
                method: "POST",
                headers: authorization_headers(),
                body: encrypt(JSON.stringify(request_data)),
            })
            .then(res => {
                res.text().then(text => {
                    // let data = JSON.parse(decrypt(text))
                    if (res.status === 201) {
                        this.setState({
                            snackbar_message: "Alert submitted", open_snackbar: true,
                            snackbar_severity: "success", loading: false,
                        })
                    } else {
                        this.setState({
                            snackbar_message: "Error submitting alert. Please try again or contact support.",
                            open_snackbar: true, loading: false,
                        })
                    }
                }).catch(() => {
                    this.setState({
                        snackbar_message: "Error submitting alert. Please try again or contact support.",
                        open_snackbar: true, loading: false,
                    })
                })
            }).catch(() => {
            this.setState({
                snackbar_message: "Error submitting alert. Please try again or contact support.",
                open_snackbar: true, loading: false,
            })
        })
    }

    addToCart(product_id) {
        let selected_product = this.state.products.filter(function (item) {
            return item.id === product_id;
        })[0];

        if (selected_product !== undefined) {
            let existing_product = this.state.shopping_cart.filter(function (item) {
                return item.id === product_id;
            })[0];

            let updated_cart = this.state.shopping_cart.slice();

            if (existing_product !== undefined) {
                let index = updated_cart.findIndex(product => product.id === product_id);

                if (index !== -1) {
                    updated_cart[index].quantity++
                }
            } else {
                selected_product.quantity = 1
                updated_cart.push(selected_product)
            }

            localStorage.setItem('shopping_cart', JSON.stringify(updated_cart))
            this.setState({shopping_cart: updated_cart})
        }
    }

    cartQuantity() {
        let sum = 0;
        this.state.shopping_cart.forEach(function (item) {
            sum += item.quantity
        })

        return sum;
    }

    cartTotal() {
        let total = 0;
        this.state.shopping_cart.forEach(function (item) {
            total += (item.quantity * parseInt(item.price))
        })

        return total;
    }

    cartProductQualtity(product_id) {
        let selected_product = this.state.shopping_cart.filter(function (item) {
            return item.id === product_id;
        })[0];

        if (selected_product !== undefined) {
            return selected_product.quantity
        }
        return 0
    }

    clearSearch() {
        this.setState({search_query: "", selected_category: null});
        this.setState({filtered_products: this.state.products});
    }

    showProductsFromACategory(category_name) {
        let filtered_products = this.state.products.filter((data) => {
            if (data.category.toLowerCase() === category_name.toLowerCase()) {
                return data;
            }
        })

        this.setState({filtered_products: filtered_products})
    }

    showProductsOnOffers() {
        let filtered_products = this.state.products.filter((data) => {
            if ((data.old_price !== null) || (data.name.toLowerCase().includes('promotion'))) {
                return data;
            }
        })

        this.setState({filtered_products: filtered_products})
    }

    setSearch(category_name, index) {
        this.setState({selected_category: index});
        if (category_name == 'Offers') {
            this.showProductsOnOffers()
        } else {
            this.showProductsFromACategory(category_name)
        }
    }

    toCurrency(number) {
        const formatter = new Intl.NumberFormat('en-us', {
                style: 'currency',
                currency: 'KSH',
            }
        )

        return formatter.format(number);
    }

    addToCartOrRemove(product, classes) {
        if (product.stocked) {
            return (
                <ButtonGroup color="primary" size="small" className={classes.group_buttons}>
                    <Fab onClick={() => this.removeFromCart(product.id)} disabled={!product.stocked}>
                        <RemoveIcon/>
                    </Fab>
                    <Button variant="contained" color="primary" disabled={!product.stocked}>
                        {this.cartProductQualtity(product.id)}
                    </Button>
                    <Fab onClick={() => this.addToCart(product.id)} disabled={!product.stocked}>
                        <AddIcon/>
                    </Fab>
                </ButtonGroup>
            )
        } else {
            return (
                <Button variant="contained" color="primary" size="small"
                        className={classes.out_of_stock_btn}
                        onClick={() => this.sendOutOfStockAlert(product.id)}>
                    Alert shop <SentimentVeryDissatisfiedIcon style={{fontSize: '18px'}}/>
                </Button>
            )
        }
    }

    redirectToHome = () => {
        const {history} = this.props;
        let right_age = (localStorage.getItem('age_above_18') !== 'true')
        let delivery_data = (localStorage.getItem('delivery_data') === null)
        let shipping_address = (localStorage.getItem('shipping_address') === null)

        if (right_age || delivery_data || shipping_address) {
            history.push('/');
        }
    }

    viewShoppingCart() {
        const {history} = this.props;
        history.push('/shopping_cart');
    }

    productImage = (product, classes) => {
        if (product.image_url) {
            return (
                <Avatar src={product.image_url} variant="square" className={classes.product_image}
                        onClick={() => this.viewProduct(product)} button/>
            )
        } else {
            return (
                <Avatar variant="square" className={classes.product_image}
                        onClick={() => this.viewProduct(product)} button>
                    <LocalBarIcon/>
                </Avatar>
            )
        }
    }

    closeDeliveryTimeDialog = () => {
        this.setState({open_delivery_time_dialog: false})
    }

    deliveryTimeDialog = () => {
        let deliver_from = Moment(this.state.delivery_data.deliver_after)
        let deliver_to = Moment(this.state.delivery_data.deliver_before)

        return (
            <Dialog
                open={this.state.open_delivery_time_dialog}
                onClose={() => this.closeDeliveryTimeDialog()}>
                <DialogTitle>Operating hours</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        The outlet will be opened
                        from <b>{deliver_from.format("dddd DD/MM/YYYY h:mma")}</b> to <b>{deliver_to.format("dddd DD/MM/YYYY h:mma")}</b>.
                        To shop here you must schedule delivery time during these operating hours.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => this.closeDeliveryTimeDialog()} color="primary" autoFocus>
                        Okay
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

    requestingOutOfOperatingHours = () => {
        let deliver_before = Moment(this.state.delivery_data.deliver_before)
        let deliver_after = Moment(this.state.delivery_data.deliver_after)
        let time_now = Moment()

        if (this.state.update_delivery_time_dialog === false)
            return

        if (time_now > deliver_before) {
            if (this.state.open_delivery_time_dialog === false)
                this.setState({open_delivery_time_dialog: true})
        } else if (time_now < deliver_after) {
            if (this.state.open_delivery_time_dialog === false)
                this.setState({open_delivery_time_dialog: true})
        }

        this.setState({update_delivery_time_dialog: false})
    }

    onOffer(product, classes) {
        if (product.stocked == false) {
            return (<span> ‎‏‏‎ ‎‏‏‎ ‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎  ‏‏‎  ‏‏‎  ‏‏‎  ‎‏‏‎ ‎‏‏‎ ‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ <Badge
                badgeContent={'SOLD OUT'} classes={{badge: classes.outOfStockCustomBadge}}/><br/>
            <span>{this.toCurrency(product.price)}</span>
            </span>)
        }
        if (product.old_price !== null) {
            return (<span> ‎‏‏‎ ‎‏‏‎ ‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎  ‎‏‏‎ ‎‏‏‎ ‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ <Badge badgeContent={'ON OFFER'}
                                                                                         classes={{badge: classes.customBadge}}/><br/>
            <span style={{textDecoration: "line-through"}}>{this.toCurrency(product.old_price)}</span>
                ‎‏‏‎ ‎‏‏‎ ‎‏‏‎
                <span style={{color: "#dc8a07"}}><b>{this.toCurrency(product.price)}</b></span>
            </span>)
        } else {
            return (this.toCurrency(product.price))
        }
    }

    verticalLeftBorder(product) {
        if ((product.old_price !== null) || (product.name.toLowerCase().includes('promotion'))) {
            return ({borderLeftColor: '#dc8a07', borderLeftStyle: 'solid', borderLeftWidth: '10px'})
        }
    }

    categoryColor(index) {
        if (index === 0) {
            return ({bgcolor: '#dc8a07', color: '#ffffff', border: null})
        } else {
            return ({bgcolor: '#ffffff', color: '#b71c1c', border: '2px solid #b71c1c'})
        }
    }

    viewProduct(product) {
        this.setState({open_product_details: true, selected_product: product})
    }

    closeProductDetailsDialog() {
        this.setState({open_product_details: false})
    }

    removeSubstring() {
        let str = this.state.selected_product.name || ""
        return str.replace(". Click to view T&Cs", "")
    }

    productDetailsDialog = (classes) => {
        return (
            <Dialog
                open={this.state.open_product_details}
                PaperProps={{
                    style: {
                        backgroundColor: '#fafafa',
                    },
                }}
                fullWidth>
                <DialogTitle>
                    <Typography variant="h6" align="center" color="primary" gutterBottom>
                        {this.removeSubstring()}
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Grid container className={classes.root}>
                        <Grid container justify="center">
                            <GridList cols={2}>
                                <GridListTile key={1}>
                                    <img src={this.state.selected_product.image_url}
                                         alt={this.state.selected_product.name}/>
                                </GridListTile>
                                <GridListTile key={2}>
                                    <Typography variant="body2" gutterBottom
                                                style={{marginTop: '60px'}}>
                                        {this.onOffer(this.state.selected_product, classes)}
                                    </Typography>
                                    {this.addToCartOrRemove(this.state.selected_product, classes)}
                                </GridListTile>
                            </GridList>
                        </Grid>
                        <Grid container justify="center">
                            <Typography variant="body1">
                                {ReactHtmlParser(this.state.selected_product.description)}
                            </Typography>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => this.closeProductDetailsDialog()} color="grey">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

    render() {
        const {classes} = this.props;
        this.redirectToHome()

        this.requestingOutOfOperatingHours();

        return (
            <React.Fragment>
                <CssBaseline/>
                <ThemeProvider theme={appTheme}>
                    <AppBar position="sticky" color="white">
                        <Toolbar className={classes.toolbar}>
                            <IconButton className={classes.iconButton} aria-label="directions">
                                <SearchIcon/>
                            </IconButton>
                            <InputBase
                                className={classes.input}
                                placeholder="Search a drink"
                                inputProps={{'aria-label': 'search a drink'}}
                                value={this.state.search_query}
                                onChange={(evt) => {
                                    this.updateSearchQuery(evt.target.value);
                                }}
                            />
                            <Button color="primary" aria-label="directions" onClick={() => this.clearSearch()}>
                                Cancel
                            </Button>
                        </Toolbar>

                        <Container maxWidth="sm" align="center" className={classes.group_btns}>
                            {this.state.product_categories.map((category, index) => (
                                <Button
                                    color="primary"
                                    variant="contained"
                                    size="small"
                                    className={classes.group_btn}
                                    style={{
                                        backgroundColor: (this.state.selected_category === index) ? '#b71c1c' : this.categoryColor(index).bgcolor,
                                        color: (this.state.selected_category === index) ? '#ffffff' : this.categoryColor(index).color,
                                        border: (this.state.selected_category === index) ? null : this.categoryColor(index).border
                                    }}
                                    onClick={() => this.setSearch(category, index)}
                                    onMouseDown={() => this.setSearch(category, index)}>
                                    {category}
                                </Button>
                            ))}
                        </Container>
                    </AppBar>
                    <List style={{marginBottom: '60px', borderRadius: '0px', backgroundColor: 'white'}}>
                        {this.state.filtered_products.map((product, index) => (
                            <ListItem key={index}
                                      style={this.verticalLeftBorder(product)} button>
                                {this.productImage(product, classes)}
                                <ListItemText
                                    classes={{primary: product.stocked ? classes.list_primary_text : classes.list_primary_text_for_out_of_stock}}
                                    primary={product.name}
                                    secondary={
                                        <div>
                                            <Typography variant="body2" gutterBottom>
                                                {this.onOffer(product, classes)}
                                            </Typography>
                                        </div>
                                    }
                                    style={{paddingRight: '18px'}}
                                    onClick={() => this.viewProduct(product)} button>
                                    {this.toCurrency(product.price)}
                                </ListItemText>

                                {this.addToCartOrRemove(product, classes)}
                            </ListItem>
                        ))}
                    </List>

                    {this.deliveryTimeDialog()}
                    {this.productDetailsDialog(classes)}
                    <Backdrop className={classes.backdrop} open={this.state.loading}>
                        <CircularProgress color="inherit"/>
                    </Backdrop>
                    <div>
                        <Snackbar
                            anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                            className={classes.snackbar}
                            open={this.state.open_snackbar}>
                            <Alert onClose={() => this.closeSnackbar()}
                                   severity={this.state.snackbar_severity || "warning"}>
                                {this.state.snackbar_message}
                            </Alert>
                        </Snackbar>
                    </div>
                    <AppBar className={classes.stickToBottom} color="primary">
                        <ButtonGroup style={{height: '60px'}} size="small" color="primary" variant="contained"
                                     fullWidth={true}>
                            <Button style={{border: '0px'}}>
                                <b>{this.cartQuantity()} ITEMS</b>
                            </Button>
                            <Button style={{border: '0px'}} onClick={() => this.viewShoppingCart()}>
                                <b>VIEW BASKET</b>
                            </Button>
                            <Button style={{border: '0px'}}>
                                <b>{this.toCurrency(this.cartTotal())}</b>
                            </Button>
                        </ButtonGroup>
                    </AppBar>
                </ThemeProvider>
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(Ordering);
