import React from 'react'

function Home(props) {
    // const {history} = props
    // history.push('/delivery_address')
    window.location.href = "https://ke.thebar.com/";
    return null
}

export default Home;
